import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "authConfig/authConfig";
import { msalInstance } from "index";

export const getActiveDirectoryAuthToken = async () => {
  // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
  const accounts = msalInstance.getAllAccounts();
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */

    return null;
  }

  const account = msalInstance.getAllAccounts()[0];

  const accessTokenRequest = {
    ...loginRequest,
    account: account,
  };

  // Use the same publicClientApplication instance provided to MsalProvider
  try {
    const accessTokenResponse = await msalInstance.acquireTokenSilent(
      accessTokenRequest
    );
    let idToken = accessTokenResponse.idToken;
    return idToken;
  } catch (e) {
    console.log(e);
    return null;
  }
};
