import React, { useState } from "react";
import { Button } from "@mui/material";
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import Pagination from "pages/homeDashboard/components/Pagination/Pagination";

import "./AdminDataGrid.css";

const AdminDataGrid = ({
  rows,
  selectedRows,
  columns,
  setSelectedRows,
  queryData,
  setShowModalMulti,
  setMultiSelectAction,
  isMultiActionLoading,
  isTableLoading,
  setCurrentPage,
  currentPage,
}) => {
  // Pagination States
  const [recordsPerPage] = useState(10);

  // MUI DataGrid Options
  const dataGridKey = JSON.stringify(rows);
  const customLocaleText = {
    noRowsLabel: "No Pending Requests",
  };

  // Open multi select modal
  const openModal = (action) => {
    setShowModalMulti(true);
    // Set multi select action to be performed
    setMultiSelectAction(action);
  };

  // Sets selected rows to the selected rows in the datagrid
  const handleSelectionModelChange = (selection) => {
    setSelectedRows(selection);
  };

  // Pagination Logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = queryData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(queryData.length / recordsPerPage);

  // Custom Footer
  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        {/* Multi-Select Action buttons */}
        <GridFooterContainer sx={{ justifyContent: "flex-start" }}>
          {selectedRows.length > 0 && (
            <div style={{ justifyContent: "flex-start" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "green",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  padding: "2px 14px",
                  borderRadius: "10px",
                  fontSize: "12px",
                  color: "white",
                  marginRight: "5px",
                  "&:hover": {
                    backgroundColor: "green",
                    color: "white",
                  },
                }}
                onClick={() => openModal("accepted")}
              >
                Accept All{" "}
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#fe0000",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  padding: "2px 14px",
                  borderRadius: "10px",
                  fontSize: "12px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#fe0000",
                    color: "white",
                  },
                }}
                onClick={() => openModal("rejected")}
              >
                Reject All{" "}
              </Button>
            </div>
          )}
        </GridFooterContainer>
        <GridFooterContainer sx={{ justifyContent: "flex-end" }}>
          {/* Add what you want here */}
          <GridFooter
            sx={{
              border: "none",
            }}
          />
          <div>
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          {/* / </Stack> */}
        </GridFooterContainer>
      </GridFooterContainer>
    );
  };

  return (
    <>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14.5px !important",
            fontWeight: "bold !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f6f6f6",
            maxHeight: "45px",
          },
          "& .MuiDataGrid-columnHeadersInner": {
            backgroundColor: "#f6f6f6 !important",
          },
          "& .MuiDataGrid-row": {
            minHeight: "55px !important",
            boxShadow: "none !important",
            borderBottom: "0.5px solid #d4d4d4",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflowX: "hidden",
          },
        }}
        rowHeight={40}
        getRowHeight={() => "auto"}
        key={dataGridKey}
        getRowId={(row) => row.id}
        id={currentRecords.id}
        rows={currentRecords}
        columns={columns}
        components={{ Footer: CustomFooter }}
        disableVirtualization
        disableSelectionOnClick
        getRowSpacing={(params) => ({
          top: params.isFirstVisible ? 0 : 3,
          bottom: params.isLastVisible ? 0 : 3,
        })}
        localeText={customLocaleText}
        checkboxSelection
        onSelectionModelChange={handleSelectionModelChange}
        selectionModel={selectedRows}
        isRowSelectable={(params) => params.row.status === "pending"}
        loadingOverlayClassName="custom-loading-indicator"
        loading={isMultiActionLoading || isTableLoading}
      />
    </>
  );
};

export default AdminDataGrid;
