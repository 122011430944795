import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import { useGetUsersByOrgAndStatusQuery } from "state/rtkQuery/user/userQueries";
import { EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_APPROVER_VIEW } from "utils/constants";

import AvatarRandomColor from "../ReusableDataGrid/AvatarRandomColor";
import ReusableDataGrid from "../ReusableDataGrid/ReusableDataGrid";

const RequestedTab = ({ orgName }) => {
  const [columns, setColumns] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const { orgId } = useParams();

  const {
    data: platformData,
    isFetching: isPlatformDataLoading,
    isError: isPlatformDataError,
  } = useGetPlatformsQuery();

  const {
    data: requestedUserListData,
    isFetching: isRequestedUserListDataLoading,
    isError: isRequestedUserListDataError,
  } = useGetUsersByOrgAndStatusQuery(
    {
      orgId: orgId,
      status: "Requested",
      platformId: platformData?._id,
      page: currentPage,
      perPage: 25,
      email: query === "" ? undefined : query,
    },
    { skip: !platformData?._id }
  );

  // Set datagrid rows
  // Combines both joined and remove requested users into a single list
  useEffect(() => {
    if (requestedUserListData) {
      setUpdatedRows(requestedUserListData?.users);
    }
  }, [requestedUserListData]);

  // Set columns
  useEffect(() => {
    if (updatedRows) {
      setColumns([
        {
          field: "name",
          headerName: `Showing ${(currentPage - 1) * 25 + 1} - ${
            (currentPage - 1) * 25 + updatedRows?.length
          } of ${requestedUserListData?.total || 0} Users`,
          headerClassName: "history-tab-totalCount-header-approver-view",
          headerAlign: "left",
          align: "left",
          width: 350,
          renderCell: (params) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                paddingLeft: "30px",
              }}
            >
              <AvatarRandomColor
                firstName={params.row.firstName}
                lastName={params.row.lastName}
                color={params.row.color}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "20px",
                }}
              >
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>{params.row.name}</span>
                  }
                  placement="top-start"
                  arrow
                >
                  <span
                    className="table-history-userEmailJoined-cell"
                    style={{ fontWeight: "600", fontSize: "14px" }}
                  >
                    {params.row.name}
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>{params.row.email}</span>
                  }
                  arrow
                  placement="bottom-start"
                >
                  {params.row.email.length >
                  EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_APPROVER_VIEW ? (
                    <div>
                      <span
                        className="table-cell-trucate-reason"
                        style={{ paddingLeft: "0", fontSize: "13px" }}
                      >
                        {params.row.email?.slice(
                          0,
                          EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_APPROVER_VIEW
                        ) + "..."}
                      </span>{" "}
                    </div>
                  ) : (
                    <span style={{ fontSize: "13px" }}>{params.row.email}</span>
                  )}
                </Tooltip>
              </div>
            </div>
          ),
        },
        {
          field: "JoinedDate",
          headerName: "Date of Request",
          headerClassName: "date-joined-table-header",
          headerAlign: "center",
          align: "center",
          width: 240,
          renderCell: (params) => (
            <span className="history-tab-joinedDate-cell ">
              {params.row.date}
            </span>
          ),
        },
        {
          field: "approvedBy",
          headerName: "Approved By",
          headerClassName: "orgName-column-header",
          headerAlign: "center",
          align: "center",
          width: 350,
          renderCell: (params) => (
            <Tooltip
              title={<p style={{ fontSize: "12px" }}>{params.row.changedBy}</p>}
            >
              <span className="table-cell-history">{params.row.changedBy}</span>
            </Tooltip>
          ),
        },
      ]);
    }
  }, [updatedRows]);

  return (
    <div>
      <ReusableDataGrid
        columns={columns}
        rows={updatedRows}
        orgName={orgName}
        viewType={"Joined History"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalRecords={requestedUserListData?.total || 0}
        setQuery={setQuery}
        query={query}
        isLoading={isRequestedUserListDataLoading || isPlatformDataLoading}
        tabDescription={
          "Users in Requested have been approved to join the organization and are in the Active Directory sync process"
        }
      />
    </div>
  );
};

export default RequestedTab;
