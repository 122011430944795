import { React, useContext, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Info } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Container, Divider, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import CryptoJS from "crypto-js";
import {
  useGetUserQuery,
  useGetUserWithOrgMembershipStatusesQuery,
} from "state/rtkQuery/user/userQueries";
import { setAuthState } from "state/slices/auth/authSlice";
import { setOpen } from "state/slices/auth/homeBannerSlice";
import { setShowAllOrgsToggle } from "state/slices/showAllOrgsSlice";
import ExtractUserPermissions from "utils/FetchUserPermissions";

import Banner from "components/Banner/Banner";

import Modal from "./components/Modals/TOSModal/Modal";
import OrgsDataGrid from "./components/OrgsDataGrid/OrgsDataGrid";
import InitiatedProgress from "./components/Table Progress Bars/InitiatedProgress";
import UserActions from "./components/UserActions/UserActions";

import BasicAlerts from "../../components/BasicAlert/BasicAlert";
import { useGetPlatformsQuery } from "../../state/rtkQuery/platform/platformQueries";
import { getMSALToken } from "../../utils/getMSALToken";

import "./HomeDashboard.css";

const HomeDashboard = () => {
  // Redux
  const authState = useSelector((state) => state.auth);
  const location = useLocation();
  const showRecommendedOrgsBanner = useSelector(
    (state) => state.homeBanner.recommendedOrgsBanner
  );
  const showDeloitteUSBanner = useSelector(
    (state) => state.homeBanner.deloitteUSBanner
  );
  const showAllOrgs = useSelector((state) => state.showAllOrgs.showAllOrgs);
  const dispatch = useDispatch();

  // Contexts
  const { instance, accounts } = useMsal();

  // Local States
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [query, setQuery] = useState("");
  const [queryData, setQueryData] = useState([]);
  const [authToken, setAuthToken] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isShowMoreOrgsLoading, setIsShowMoreOrgsLoading] = useState(false);

  //Get the query from URL through searchParams and set it in the state
  useEffect(() => {
    const queryParam = searchParams.get("query");
    if (queryParam) {
      setQuery(queryParam);
      dispatch(setShowAllOrgsToggle(true));
    }
  }, [location]);

  // Handle search change
  const handleSearchChange = (event) => {
    setQuery(event.target.value);
    setSearchParams({ query: event.target.value });
  };

  // Fetch MSAL token
  useEffect(() => {
    getMSALToken(instance, accounts, setAuthToken);
  }, []);

  //Clear search query if query is empty in URL
  useEffect(() => {
    const queryParam = searchParams.get("query");
    if (queryParam === "" || queryParam === null) {
      setQuery("");
    }
  }, [searchParams]);

  // RTK Query for getting platform data
  const {
    data: platformData,
    isLoading: isPlatformDataLoading,
    isSuccess: isPlatformDataSuccess,
    error: isPlatformDataError,
  } = useGetPlatformsQuery();

  // RTK Query for getting user entry by email
  const {
    data: userData,
    status: userDataStatus,
    isLoading: isUserDataLoading,
    isSuccess: isUserDataSuccess,
    error: userDataError,
  } = useGetUserQuery(
    { email: accounts?.[0]?.username },
    { skip: !isPlatformDataSuccess && !platformData }
  );

  // User TOS check
  useEffect(() => {
    if (
      userData &&
      userData.length !== 0 &&
      userData[0].platforms[platformData._id].tos_accepted === true
    ) {
      setShowTable(true);
      setIsPageLoading(false);
    } else if (
      userData &&
      (userData.length === 0 ||
        userData[0].platforms[platformData._id].tos_accepted === false)
    ) {
      setShowModal(true);
      setIsPageLoading(false);
    } else if (userDataError) {
      setIsPageLoading(false);
    }
  }, [userData, userDataError]);

  // RTK Query for getting user with org membership statuses if user data and platform data has been fetched
  const {
    data: userOrgsData,
    isLoading: isUserOrgsDataLoading,
    isSuccess: isUserOrgsDataSuccess,
  } = useGetUserWithOrgMembershipStatusesQuery(
    { userId: userData?.[0]?._id, platformId: platformData?._id },
    {
      skip:
        !isUserDataSuccess || !isPlatformDataSuccess || userData?.length === 0,
      pollingInterval: 300000,
    }
  );

  // Set rows and queryData once userOrgsData is fetched
  useEffect(() => {
    if (isUserOrgsDataSuccess) {
      let orgsToShow = [...userOrgsData[0]];
      if (showAllOrgs) {
        orgsToShow = [...orgsToShow, ...userOrgsData[1]];
      }

      setRows(orgsToShow);
      setQueryData(orgsToShow);

      setIsInitialRender(false);
      setTimeout(() => {
        setIsShowMoreOrgsLoading(false);
      }, [1000]);
    }
  }, [isUserOrgsDataSuccess, userOrgsData, showAllOrgs]);

  /*
   * Extract user permissions from userOrgsData and set it in authState
   * If permissions are not present in authState, get permissions
   */
  useEffect(() => {
    const getUserPermissions = async () => {
      let authStateUpdated = JSON.parse(JSON.stringify(authState));
      authStateUpdated.permissions = await ExtractUserPermissions([
        ...userOrgsData[0],
        ...userOrgsData[1],
      ]);

      localStorage.setItem(
        "authStateEncrypted",
        CryptoJS.AES.encrypt(
          JSON.stringify(authStateUpdated),
          "someKey"
        ).toString()
      );

      await dispatch(setAuthState(authStateUpdated));
    };

    // If userOrgsData is fetched and permissions are not present in authState, get permissions
    if (
      authState?.permissions?.length === 0 &&
      isUserOrgsDataSuccess &&
      platformData
    ) {
      getUserPermissions();
    }
  }, [isUserOrgsDataSuccess, userOrgsData]);

  // Set columns for MUI DataGrid once userOrgsData is fetched
  useEffect(() => {
    if (authToken) {
      setColumns([
        // Org Name
        {
          field: "organization",
          headerName: "Organization",
          headerClassName: "orgName-column-header",
          headerAlign: "left",
          align: "left",
          width: 262,
          renderCell: (params) => (
            <Tooltip
              title={
                <p
                  style={{
                    fontSize: "13px",
                    width: "230px",
                    height: "fitContent",
                  }}
                >
                  {params.row.recommended
                    ? `The ${params.row.orgDisplayName} org
                  is recommended for you to join.`
                    : ` ${params.row.orgDisplayName}`}
                </p>
              }
              placement="left"
            >
              <span className="table-cell-home-OrgDisplayName">
                <p className="table-cell-orgName ">
                  <strong>{params.row.orgDisplayName}</strong>
                </p>

                {params.row.recommended ? (
                  <span>
                    <i>Recommended</i>
                  </span>
                ) : (
                  ""
                )}
              </span>
            </Tooltip>
          ),
        },
        // Approval Required
        {
          field: "approvalRequired",
          headerName: "Approval Required",
          description: "Requires Approval",
          width: 160,
          headerAlign: "center",
          align: "center",
          filterable: false,
          renderCell: (params) => (
            <Tooltip
              title={
                params.row.approvalRequired === "Yes"
                  ? "Approval Required"
                  : "Approval Not Required"
              }
            >
              <span className="approval-column-val">
                <CircleIcon
                  style={{
                    color:
                      params.row.approvalRequired === "Yes"
                        ? "#FF0000"
                        : "green",
                  }}
                />
              </span>
            </Tooltip>
          ),
        },
        // Request Status
        {
          field: "status",
          headerName: "Request Status",
          width: 380,
          align: "center",
          headerAlign: "center",
          filterable: true,
          renderCell: (params) => <InitiatedProgress {...{ params }} />,
        },
        // Action Buttons
        {
          field: "action",
          headerAlign: "center",
          headerName: "Actions",
          align: "center",
          width: 350,
          filterable: false,
          renderCell: (params) => <UserActions {...{ params }} />,
        },
      ]);
    }
  }, [authToken, userData]);

  // Set queryData based on row data and initial query (this data will be shown in the table)
  useEffect(() => {
    setQueryData(
      rows.filter((post) => {
        if (query === "") {
          return rows;
        } else if (
          post.orgDisplayName.toLowerCase().includes(query.toLowerCase())
        ) {
          return post;
        }
      })
    );

    // Set current page to last available page if current page is greater than total pages
    if (currentPage > Math.ceil(queryData.length / 10)) {
      setCurrentPage(Math.max(1, Math.ceil(queryData.length / 10)));
    }
  }, [rows]);

  // Handles subsequent search query changes
  useEffect(() => {
    setCurrentPage(1);
    setQueryData(
      rows.filter((post) => {
        if (query === "") {
          return rows;
        } else if (
          post.orgDisplayName.toLowerCase().includes(query.toLowerCase())
        ) {
          return post;
        }
      })
    );
  }, [query]);

  // Styles for MUI TextField
  const textStyles = styled({
    root: {
      "& .Mui-focused": {
        color: "#008080",
        outline: "1px solid  #008080",
      },
    },
  });

  // Handles closing TOS modal
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
    } else {
      setShowModal(false);
      setShowTable(true);
    }
  };

  // Show all organizations on the table
  const showAllOrganizations = () => {
    setIsShowMoreOrgsLoading(true);
    dispatch(setShowAllOrgsToggle(!showAllOrgs));
  };

  // Theme for tooltip
  const welcomeToolTipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "0.9rem", // Adjust this value to your needs
            padding: "8px 12px",
          },
        },
      },
    },
  });

  const theme = createTheme({});

  return (
    <Container sx={{ paddingTop: "0rem" }}>
      {/* Loading Spinner for initial render */}
      {isInitialRender && isPageLoading && isUserDataLoading ? (
        <Box
          sx={{
            height: 420,
            marginTop: "4rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <TailSpin
            color="hsl(81.43deg 69.37% 43.53%)"
            height={50}
            width={50}
          />
        </Box>
      ) : (
        <div>
          {/* TOS Modal */}
          {showModal ? (
            <Modal
              show={showModal}
              close={handleClose}
              email={authState.userAccount?.username}
              firstName={authState.userAccount?.givenName}
              lastName={authState.userAccount?.surname}
              platformId={platformData?._id}
              platformName={platformData?.name}
              userData={userData}
            />
          ) : showTable ? (
            <Box
              sx={{
                height: 740,
                marginTop: "2.5rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {" "}
              {showRecommendedOrgsBanner && (
                <Banner
                  bgColor={"#DDEFE8"}
                  onClickFunc={() =>
                    dispatch(setOpen({ recommendedOrgsBanner: false }))
                  }
                  attention={"high"}
                  content={`Please avoid selecting all organizations marked as <i>"Recommended"</i>. These are marked to assist you in selecting an org if you are unsure which one to request access to`}
                />
              )}
              {showDeloitteUSBanner && (
                <Banner
                  bgColor={"#DDEFE8"}
                  onClickFunc={() =>
                    dispatch(setOpen({ deloitteUSBanner: false }))
                  }
                  attention={"high"}
                  content={`Users that are in <strong>US Consulting</strong> and need access to the <strong>Deloitte US Consulting</strong> organization must request access <a href="https://engineeringplatforms.deloitte.com">here</a>`}
                />
              )}
              {/* Header */}
              <div className="headerSearchFlex">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <span style={{ fontSize: "24px", fontWeight: "800" }}>
                    GitHub Global Organizations
                  </span>
                </div>
              </div>
              <Divider />
              <div
                className="welcome-message"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "14px",
                  }}
                >
                  <span>
                    Organizations marked as {<i>"Recommended"</i>} are suggested
                    for you to join based on the information in your Active
                    Directory profile
                  </span>
                  <ThemeProvider theme={welcomeToolTipTheme}>
                    <Tooltip
                      placement="right"
                      title={
                        <div>
                          {
                            "Recommendations are currently based on department, business function and country. This may change as new filters continue to be developed"
                          }
                        </div>
                      }
                      sx={{
                        marginLeft: "5px",
                        color: "#008080",
                        cursor: "pointer",
                      }}
                    >
                      <Info />
                    </Tooltip>
                  </ThemeProvider>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  {/* Search TextField */}
                  <ThemeProvider theme={theme}>
                    <TextField
                      id="outlined-search"
                      placeholder="Search organizations"
                      type="search"
                      className={textStyles.root}
                      variant="outlined"
                      InputProps={{
                        style: {
                          height: "31px",
                          minWidth: "300px",
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => handleSearchChange(event)}
                      value={query}
                    />
                  </ThemeProvider>
                </div>
                <div>
                  <span
                    style={{
                      color: showAllOrgs ? "black" : "black",
                      fontWeight: showAllOrgs ? "bold" : "normal",
                    }}
                  >
                    {"Show All Organizations"}
                  </span>
                  <ThemeProvider theme={welcomeToolTipTheme}>
                    <Tooltip
                      title={
                        showAllOrgs
                          ? "Currently showing all orgs. Toggle to show recommended orgs only."
                          : "Currently showing joined and recommended orgs only. Toggle to show all orgs."
                      }
                      arrow
                    >
                      <Switch
                        checked={showAllOrgs}
                        aria-label="ShowAllOrgs"
                        onClick={() => showAllOrganizations()}
                        sx={{
                          "& .Mui-checked": {
                            color: "hsl(81.43deg 69.37% 43.53%) !important",
                          },
                          "& .Mui-checked + .MuiSwitch-track": {
                            backgroundColor:
                              "hsl(81.43deg 69.37% 43.53%) !important",
                          },
                        }}
                      />
                    </Tooltip>
                  </ThemeProvider>
                </div>
              </div>
              {/* DataGrid */}
              <OrgsDataGrid
                totalRecords={userOrgsData?.length}
                columns={columns}
                isTableLoading={isUserOrgsDataLoading}
                isInitialRender={isInitialRender}
                isShowMoreOrgsLoading={isShowMoreOrgsLoading}
                rows={rows}
                queryData={queryData}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
              {/* Footer/Support Request Form */}
            </Box>
          ) : (
            <></>
          )}
          <div style={{ marginTop: "30px", padding: "10px 0px" }}>
            <p>
              Please fill our{" "}
              <a
                href="https://deloitteglobal.service-now.com/sp?id=sc_cat_item&sys_id=4a417b4f1b17855cbcae55b0604bcb1c"
                target="_blank"
                style={{ color: "rgb(0 151 215)", fontWeight: "500" }}
                rel="noreferrer"
              >
                Support Request
              </a>
              &nbsp; Form if you face any issues.
            </p>
          </div>
        </div>
      )}
    </Container>
  );
};

export default HomeDashboard;
