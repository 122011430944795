import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import { useGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import { useUpdateUserOrgStatusBatchMutation } from "state/rtkQuery/user/userMutations";

import LargeDataPagination from "./LargeDataPagination/LargeDataPagination";
import ReusableMultiSelectModal from "./ReusableMultiSelectModal";

const ReusableDataGrid = ({
  columns,
  rows,
  orgName,
  viewType,
  setCurrentPage,
  currentPage,
  totalRecords,
  isLoading,
  setQuery,
  query,
  checkboxSelection,
  tabDescription,
}) => {
  const [queryData, setQueryData] = useState([]);
  const [isDataProcessingloading, setIsDataProcessingLoading] = useState(true);
  const [showMultiSelectModal, setShowMultiSelectModal] = useState(false);

  const { orgId } = useParams();
  const userEmail = useSelector((state) => state.auth.userAccount?.username);
  const { data: platformData } = useGetPlatformsQuery();

  // Key for re-rendering the datagrid
  const dataGridKey = JSON.stringify(rows);

  // Custom locale text for datagrid empty rows
  const customLocaleText = {
    noRowsLabel: "No Users Found",
  };

  const [selectedRows, setSelectedRows] = useState([]);

  // Sets selected rows to the selected rows in the datagrid
  const handleSelectionModelChange = (selection) => {
    setSelectedRows(selection);
  };

  const [recordsPerPage] = useState(25);

  // Search TextField styles
  const textStyles = styled({
    root: {
      "& .Mui-focused": {
        color: "#008080",
        outline: "1px solid  #008080",
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontSize: "14px",
      },
    },
  });

  useEffect(() => {
    if (rows) {
      setQueryData(rows);
      setIsDataProcessingLoading(false);
    }
  }, [rows]);

  const [currentRecords, setCurrentRecords] = useState(queryData);

  useEffect(() => {
    if (queryData) {
      setCurrentRecords(queryData);
    }
  }, [currentPage, recordsPerPage, queryData]);

  const calcPages = useMemo(() => {
    return Math.ceil(totalRecords / recordsPerPage);
  }, [totalRecords]);

  const handleSearchChange = (event) => {
    setCurrentPage(1);
    setQuery(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
    } else {
      setShowMultiSelectModal(false);
      setSelectedRows([]);
    }
  };

  const openModal = () => {
    setShowMultiSelectModal(true);
  };

  // Batch update user org status for multi-select functionality
  const [
    batchUpdateUserOrgStatus,
    { data: batchUpdateData, isLoading: isBatchUpdateUserOrgStatusLoading },
  ] = useUpdateUserOrgStatusBatchMutation();

  const action = (platformId, orgId, userEmail) => {
    return (rejectReason, otherReason, rowData) => {
      batchUpdateUserOrgStatus({
        platformId: platformId,
        orgId: orgId,
        userEmail: userEmail,
        rejectReason: rejectReason,
        otherReason: otherReason,
        rowData: rowData,
      });
    };
  };

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <GridFooterContainer sx={{ justifyContent: "flex-start" }}>
          {selectedRows.length > 0 && (
            <div style={{ justifyContent: "flex-start" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#fe0000",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  padding: "2px 14px",
                  borderRadius: "10px",
                  fontSize: "12px",
                  color: "white",
                  marginRight: "5px",
                  "&:hover": {
                    backgroundColor: "#fe0000",
                    color: "white",
                  },
                }}
                onClick={() => openModal()}
              >
                {viewType === "Joined History" ? "Revoke All" : "Reset All"}
              </Button>
            </div>
          )}
          <GridFooter
            sx={{
              border: "none",
              display: "flex",
              justifyContent: "space-between",
            }}
          />
        </GridFooterContainer>
        <GridFooterContainer>
          <LargeDataPagination
            nPages={calcPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </GridFooterContainer>
      </GridFooterContainer>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "3rem",
      }}
    >
      {showMultiSelectModal && (
        <ReusableMultiSelectModal
          show={showMultiSelectModal}
          close={handleClose}
          selectedRows={selectedRows}
          allRows={queryData}
          action={() => action(platformData?._id, orgId, userEmail)}
        />
      )}
      <div
        style={{
          width: "100%",
          padding: "1rem",
          paddingTop: "0",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: "18px",
            display: "flex",
            flexDirection: "column",
            width: "80%",
            marginBottom: "0.5rem",
          }}
        >
          {orgName || ""}
          <Typography variant="subtitle1">Org History</Typography>
        </div>
        <hr style={{ backgroundColor: "#d0d7de", width: "100%" }}></hr>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#808080",
              lineHeight: "1.5",
              marginTop: "0.5rem",
              width: "60%",
            }}
          >
            {tabDescription}
          </span>
          {/* Search TextField */}
          <TextField
            id="outlined-search"
            placeholder="Search Users By Email"
            width="100%"
            type="search"
            className={textStyles.root}
            variant="outlined"
            InputProps={{
              style: {
                height: "31px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div style={{ height: "600px", width: "100%" }}>
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "13px !important",
              fontWeight: "normal !important",
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#f6f6f6",
              maxHeight: "45px",
              fontSize: "12px !important",
            },
            "& .MuiDataGrid-row": {
              minHeight: "60px !important",
              boxShadow: "none !important",
              borderBottom: "1px solid #d4d4d4",
            },
            "& .MuiDataGrid-cell": {
              fontSize: "14px !important",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflowX: "hidden",
            },
          }}
          components={{ Footer: CustomFooter }}
          key={dataGridKey}
          getRowId={(row) => row.id}
          rows={currentRecords}
          columns={columns}
          disableVirtualization
          disableSelectionOnClick
          checkboxSelection={checkboxSelection}
          localeText={customLocaleText}
          onSelectionModelChange={handleSelectionModelChange}
          selectionModel={selectedRows}
          isRowSelectable={(params) =>
            params.row.status === "joined" ||
            params.row.status === "rejected" ||
            params.row.status === "removed"
          }
          loadingOverlayClassName="custom-loading-indicator"
          loading={
            isLoading ||
            isDataProcessingloading ||
            isBatchUpdateUserOrgStatusLoading
          }
        />
      </div>
    </div>
  );
};

export default ReusableDataGrid;
