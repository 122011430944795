import React from "react";

import "./TableLegend.css";

const TableLegend = () => {
  return (
    <div className="legend-container">
      <div className="legend-entry-container">
        <hr
          className="legend-hr"
          style={{ border: "2px solid #007CB0", width: "2rem" }}
        />
        <p className="p-9" style={{ fontWeight: "bold" }}>
          Non-Member
        </p>
      </div>
      <div className="legend-entry-container">
        <hr
          className="legend-hr"
          style={{ border: "2px solid #ED8B00", width: "2rem" }}
        />
        <p className="p-9" style={{ fontWeight: "bold" }}>
          In Progress
        </p>
      </div>
      <div className="legend-entry-container">
        <hr
          className="legend-hr"
          style={{ border: "2px solid #43B02A", width: "2rem" }}
        />
        <p className="p-9" style={{ fontWeight: "bold" }}>
          Joined
        </p>
      </div>
      <div className="legend-entry-container">
        <hr
          className="legend-hr"
          style={{ border: "2px solid #FF0000", width: "2rem" }}
        />
        <p className="p-9" style={{ fontWeight: "bold" }}>
          Failed
        </p>
      </div>
    </div>
  );
};

export default TableLegend;
