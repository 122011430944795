import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import UserCountDisplay from "../userCountDisplay";

import "./SideNav.css";

const SideNav = ({
  filter,
  setFilter,
  joinedCount,
  rejectedCount,
  removeRequestedCount,
  removedCount,
  requestedCount,
}) => {
  const { orgId } = useParams();
  const permissions = useSelector((state) => state.auth.permissions);
  const [orgPermissions, setOrgPermissions] = useState();

  useEffect(() => {
    if (orgId && permissions) {
      setOrgPermissions(
        permissions?.filter((permission) => permission.orgId === orgId)
      );
    }
  }, [orgId, permissions]);

  return (
    <div
      style={{
        // border: "1px black solid",
        paddingRight: "2rem",
        paddingTop: "0",
      }}
    >
      <div
        style={{
          border: "1px solid #d0d7de",
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          color: "black",
        }}
      >
        <div className="side-nav-filter-header">Filter Types of Users</div>
        {/* Joined Tab */}
        <div
          className={
            "side-nav-filter-option " +
            (filter === "Joined" && "side-nav-filter-option-joined-active")
          }
          onClick={() => setFilter("Joined")}
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            Joined
          </p>
          <UserCountDisplay count={joinedCount} backgroundColor={"#afb8c133"} />{" "}
        </div>
        {/* Requested Tab */}
        <div
          className={
            "side-nav-filter-option " +
            (filter === "Requested" &&
              "side-nav-filter-option-requested-active")
          }
          onClick={() => setFilter("Requested")}
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            Requested
          </p>
          <UserCountDisplay
            count={requestedCount}
            backgroundColor={"#afb8c133"}
          />{" "}
        </div>
        {/* Remove Requested Tab */}
        <div
          className={
            "side-nav-filter-option " +
            (filter === "Remove-Requested" &&
              "side-nav-filter-option-removeRequested-active")
          }
          onClick={() => setFilter("Remove-Requested")}
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            Removals Requested
          </p>
          <UserCountDisplay
            count={removeRequestedCount}
            backgroundColor={"#afb8c133"}
          />{" "}
        </div>
        {/* Rejected Tab */}
        <div
          className={
            "side-nav-filter-option " +
            (filter === "Rejected" && "side-nav-filter-option-rejected-active")
          }
          onClick={() => setFilter("Rejected")}
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            Rejected
          </p>
          <UserCountDisplay
            count={rejectedCount}
            backgroundColor={"#afb8c133"}
          />{" "}
        </div>
        {/* Removed Tab */}
        {orgPermissions?.[0]?.userType === "Owner" && (
          <div
            className={
              "side-nav-filter-option " +
              (filter === "Removed" && "side-nav-filter-option-removed-active")
            }
            style={{ borderBottom: "none" }}
            onClick={() => setFilter("Removed")}
          >
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0px",
              }}
            >
              Removed
            </p>
            <UserCountDisplay
              count={removedCount}
              backgroundColor={"#afb8c133"}
            />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default SideNav;
