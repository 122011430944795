import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { json, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import { useUpdateUserOrgStatusMutation } from "state/rtkQuery/user/userMutations";
import { useGetUsersByOrgAndStatusQuery } from "state/rtkQuery/user/userQueries";
import {
  EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_APPROVER_VIEW,
  EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_OWNER_VIEW,
} from "utils/constants";
import { generateRandomColor } from "utils/generateRandomColor";

import BasicAlerts from "components/BasicAlert/BasicAlert";

import AvatarRandomColor from "../ReusableDataGrid/AvatarRandomColor";
import ReusableActions from "../ReusableDataGrid/ReusableActions";
import ReusableDataGrid from "../ReusableDataGrid/ReusableDataGrid";

const JoinedTab = ({ userJoinedHistoryMockList, orgName }) => {
  const [columns, setColumns] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const { orgId } = useParams();
  const [orgPermissions, setOrgPermissions] = useState();
  const [emailTruncateLimit, setEmailTruncateLimit] = useState(
    EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_OWNER_VIEW
  );

  const permissions = useSelector((state) => state.auth.permissions);

  useEffect(() => {
    if (orgId && permissions) {
      setOrgPermissions(
        permissions?.filter((permission) => permission.orgId === orgId)
      );
    }
  }, [orgId, permissions]);

  useEffect(() => {
    if (orgPermissions && orgPermissions?.[0]?.userType === "Approver") {
      setEmailTruncateLimit(
        EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_APPROVER_VIEW
      );
    }
  }, [orgPermissions]);

  const {
    data: platformData,
    isFetching: isPlatformDataLoading,
    isError: isPlatformDataError,
  } = useGetPlatformsQuery();

  const {
    data: joinedUserListData,
    isFetching: isJoinedUserListDataLoading,
    isError: isJoinedUserListDataError,
  } = useGetUsersByOrgAndStatusQuery(
    {
      orgId: orgId,
      status: "Joined",
      platformId: platformData?._id,
      page: currentPage,
      perPage: 25,
      email: query === "" ? undefined : query,
    },
    { skip: !platformData?._id }
  );

  // Set datagrid rows
  // Combines both joined and remove requested users into a single list
  useEffect(() => {
    if (joinedUserListData) {
      setUpdatedRows(joinedUserListData?.users);
    }
  }, [joinedUserListData]);

  // Mutation to update user status
  const [
    updateUserOrgStatus,
    {
      isLoading: isUpdateUserOrgStatusLoading,
      isError: isUpdateUserOrgStatusError,
    },
  ] = useUpdateUserOrgStatusMutation();

  // Logged in user's email
  const userEmail = useSelector((state) => state.auth.userAccount?.username);

  // Function to execute when button is clicked in action column of datagrid
  const action = (userId, platformId, orgId, userEmail) => {
    return (rejectReason, otherReason) => {
      updateUserOrgStatus({
        userId: userId,
        body: {
          platforms: {
            [platformId]: {
              organizations: {
                [orgId]: {
                  membership_status: "Remove-Requested",
                  reason: rejectReason === "Other" ? otherReason : rejectReason,
                  changed_by: userEmail.toLowerCase(),
                },
              },
            },
          },
        },
        attribute: "membership_status",
      });
    };
  };

  // Set columns
  useEffect(() => {
    if (updatedRows && orgPermissions) {
      setColumns([
        {
          field: "name",
          headerName: `Showing ${(currentPage - 1) * 25 + 1} - ${
            (currentPage - 1) * 25 + updatedRows?.length
          } of ${joinedUserListData?.total || 0} Users`,
          headerClassName:
            orgPermissions?.[0]?.userType === "Owner"
              ? "history-tab-totalCount-header"
              : "history-tab-totalCount-header-approver-view",
          headerAlign: "left",
          align: "left",
          width: orgPermissions?.[0]?.userType === "Owner" ? 270 : 350,
          renderCell: (params) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                paddingLeft:
                  orgPermissions?.[0]?.userType === "Owner" ? "0px" : "30px",
              }}
            >
              <AvatarRandomColor
                firstName={params.row.firstName}
                lastName={params.row.lastName}
                color={params.row.color}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "20px",
                }}
              >
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>{params.row.name}</span>
                  }
                  placement="top-start"
                  arrow
                >
                  <span
                    className="table-history-userEmailJoined-cell"
                    style={{ fontWeight: "600", fontSize: "14px" }}
                  >
                    {params.row.name}
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>{params.row.email}</span>
                  }
                  arrow
                  placement="bottom-start"
                >
                  {params.row.email.length > emailTruncateLimit ? (
                    <div>
                      <span
                        className="table-cell-trucate-reason"
                        style={{ paddingLeft: "0", fontSize: "13px" }}
                      >
                        {params.row.email?.slice(0, emailTruncateLimit) + "..."}
                      </span>{" "}
                    </div>
                  ) : (
                    <span style={{ fontSize: "13px" }}>{params.row.email}</span>
                  )}
                </Tooltip>
              </div>
            </div>
          ),
        },
        {
          field: "JoinedDate",
          headerName: "Date of Joining",
          headerClassName: "date-joined-table-header",
          headerAlign: "center",
          align: "center",
          width: orgPermissions?.[0]?.userType === "Owner" ? 140 : 240,
          renderCell: (params) => (
            <span className="history-tab-joinedDate-cell ">
              {params.row.date}
            </span>
          ),
        },
        {
          field: "approvedBy",
          headerName: "Approved By",
          headerClassName: "orgName-column-header",
          headerAlign: "center",
          align: "center",
          width: orgPermissions?.[0]?.userType === "Owner" ? 280 : 350,
          renderCell: (params) => (
            <Tooltip
              title={<p style={{ fontSize: "12px" }}>{params.row.changedBy}</p>}
            >
              <span className="table-cell-history">{params.row.changedBy}</span>
            </Tooltip>
          ),
        },
        orgPermissions?.[0]?.userType === "Owner" && {
          field: "action",
          headerAlign: "center",
          headerName: "Actions",
          align: "center",
          width: orgPermissions?.[0]?.userType === "Owner" ? 220 : 0,
          filterable: false,
          renderCell: (params) => (
            <ReusableActions
              params={params}
              action={
                params.row.status === "joined"
                  ? () =>
                      action(params.row.id, platformData?._id, orgId, userEmail)
                  : () => {}
              }
            />
          ),
        },
      ]);
    }
  }, [updatedRows, orgPermissions]);

  return (
    <div>
      <ReusableDataGrid
        columns={columns}
        rows={updatedRows}
        orgName={orgName}
        viewType={"Joined History"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalRecords={joinedUserListData?.total || 0}
        checkboxSelection={orgPermissions?.[0]?.userType === "Owner"}
        setQuery={setQuery}
        query={query}
        isLoading={
          isJoinedUserListDataLoading ||
          // isRemoveRequestedUserListDataLoading ||
          isUpdateUserOrgStatusLoading ||
          isPlatformDataLoading
        }
        tabDescription={
          "Users in Joined have been successfully added to the organization. These users will have access to the organization"
        }
      />
    </div>
  );
};

export default JoinedTab;
