import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container } from "@mui/material";
import { removeAllErrors, removeError } from "state/slices/auth/ErrorSlice";

import BasicAlerts from "components/BasicAlert/BasicAlert";

const Errors = () => {
  const errors = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const openErrors = () => {
    setOpen(!open);
  };

  return (
    <Container
      sx={{
        height: "auto !important",
        maxWidth: "1300px !important",
        flex: "1",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
          marginBottom: Object.keys(errors)?.length > 0 ? "20px" : "0",
        }}
      >
        {Object.keys(errors)?.length > 0 && (
          <div style={{ marginTop: "20px", width: "100%" }}>
            <BasicAlerts
              severity={"error"}
              message={`${Object.keys(errors)?.length} error(s) occurred`}
              action={() => dispatch(removeAllErrors())}
              expandable={true}
              expandableText={open ? "Hide Errors" : "View Errors"}
              expandableAction={openErrors}
              expandableState={open}
              expandableData={errors}
            />
          </div>
        )}
        {/* {Object.entries(errors).map(([key, error]) => (
          <div style={{ marginTop: "20px", width: "100%" }} key={key}>
            <BasicAlerts
              severity={"error"}
              message={`${error.status}: ${error.message}`}
              action={() => dispatch(removeError(key))}
            />
          </div>
        ))} */}
      </Box>
    </Container>
  );
};

export default Errors;
