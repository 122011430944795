import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import "./Banner.css";

const Banner = ({ bgColor, content, onClickFunc, attention }) => {
  return (
    <div
      style={{
        padding: "16px",
        backgroundColor: bgColor,
        borderRadius: "4px",
        fontSize: "13px",
        marginBottom: "30px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        {attention === "high" ? (
          <ErrorOutlineIcon style={{ color: "red" }} />
        ) : attention === "medium" ? (
          <ErrorOutlineIcon style={{ color: "orange" }} />
        ) : attention === "low" ? (
          <ErrorOutlineIcon style={{ color: "green" }} />
        ) : null}
        <span dangerouslySetInnerHTML={{ __html: content }}></span>
      </div>
      <span style={{ cursor: "pointer" }} onClick={onClickFunc}>
        <CloseIcon className="banner-close-icon" />
      </span>
    </div>
  );
};

export default Banner;
