import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import { useUpdateUserOrgStatusMutation } from "state/rtkQuery/user/userMutations";

import ConfirmationModal from "../Modals/ConfirmationModal/ConfirmationModal";

import "./UserActions.css";

const UserActions = ({ params }) => {
  const navigate = useNavigate();

  // Local states
  const [showModal, setShowModal] = useState(false);
  const [requestReason, setRequestReason] = useState("");

  const [permissions, setPermissions] = useState([]);

  const userPermissions = useSelector((state) => state.auth.permissions);

  useEffect(() => {
    if (userPermissions && params) {
      setPermissions(
        userPermissions.find((permission) => permission.orgId === params.row.id)
      );
    }
  }, [userPermissions, params]);

  // RTK Query for updating user organization status
  const [
    updateUserOrgStatus,
    {
      data: updateUserOrgResponseData,
      isLoading: isUpdateUserOrgLoading,
      isSuccess: isUpdateUserOrgSuccess,
      error: updateUserOrgError,
    },
  ] = useUpdateUserOrgStatusMutation();

  // Update user organization status api call function
  const onAddUserToOrganizationHandler = () => {
    // Close modal
    handleClose();
    // Update user organization status
    updateUserOrgStatus({
      userId: params.row.userId,
      body: {
        platforms: {
          [params.row.platformId]: {
            organizations: {
              [params.row.id]: {
                membership_status:
                  params.row.approvalRequired === "Yes"
                    ? "Pending"
                    : "Requested",
                reason: requestReason,
                changed_by: params.row.email.toLowerCase(),
              },
            },
          },
        },
      },
      attribute: "membership_status",
    });
  };

  // Close modal function
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
    } else {
      setShowModal(false);
      // Reset request reason on modal close
      setRequestReason("");
    }
  };

  // Open modal function
  const openModal = () => {
    setShowModal(true);
  };

  // Navigates to admin dashboard
  const viewRequests = () => {
    navigate(`/admin/${params.row.id}/dashboard`);
  };

  return (
    <div>
      {/* Confirmation modal component */}
      {showModal && (
        <ConfirmationModal
          show={showModal}
          close={handleClose}
          params={params.row}
          onAddOrganizationHandler={onAddUserToOrganizationHandler}
          requestReason={requestReason}
          setRequestReason={setRequestReason}
        />
      )}
      <Box sx={{ m: 1, position: "relative" }}>
        {/* Request Access Button */}
        {permissions?.userType !== "Owner" && (
          <Button
            variant="contained"
            sx={{
              minWidth: "9rem",
              padding: "5px",
              margin: "5px",
            }}
            className="make-request-btn"
            onClick={() => openModal()}
            disabled={params.row.action === "Add" ? false : true}
          >
            {isUpdateUserOrgLoading ? (
              <>
                <CircularProgress size={14} />
                <span style={{ marginLeft: "5px" }}>Requesting...</span>
              </>
            ) : params.row.status == "In-Progress" ? (
              "In Progress"
            ) : params.row.status == "Pending" ? (
              "Approval Pending"
            ) : params.row.status == "Joined" ? (
              "Joined"
            ) : params.row.status == "Failed" ? (
              "Resend Request"
            ) : params.row.status == "Rejected" ? (
              "Rejected"
            ) : params.row.status == "Removed" ? (
              "Removed"
            ) : params.row.approvalRequired == "Yes" ? (
              "Request Access"
            ) : (
              "Join"
            )}
          </Button>
        )}
        {/* View Requests Button */}
        {(params.row.userType === "Owner" ||
          params.row.userType === "Approver") && (
          <Button
            variant="outlined"
            sx={{
              minWidth: "9rem",
              margin: "5px",
              padding: "5px",
            }}
            className="viewRequestButton"
            onClick={() => viewRequests()}
          >
            View Requests
          </Button>
        )}
      </Box>
    </div>
  );
};

export default UserActions;
