import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import MenuIcon from "@mui/icons-material/Menu";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import SettingsIcon from "@mui/icons-material/Settings";
import { useGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import { useGetUsersByOrgAndStatusQuery } from "state/rtkQuery/user/userQueries";

import "./AdminNavBar.css";

const AdminNavBar = () => {
  const { orgId } = useParams();
  const sideMenuRef = React.useRef(null);
  const sideMenuContainerRef = React.useRef(null);
  const [openOrgHistorySubMenu, setOpenOrgHistorySubMenu] = useState(false);

  const [openSideMenu, setOpenSideMenu] = useState(false);

  const permissions = useSelector((state) =>
    state.auth.permissions.find((obj) => obj?.orgId === orgId)
  );

  // fix sidebar when scrolling down
  window.onscroll = () => {
    if (sideMenuRef.current) {
      if (
        document.body.scrollTop > 60 ||
        document.documentElement.scrollTop > 60
      ) {
        sideMenuRef.current.style.position = "fixed";
        sideMenuRef.current.style.top = "0";
      } else {
        sideMenuRef.current.style.position = "relative";
      }
    }
  };

  // Fetch pending approval count for to populate pending approvals badge

  // RTK Query to get platform data
  const { data: platformData, isSuccess: isPlatformDataSuccess } =
    useGetPlatformsQuery();

  // RTK Query to get users by org and status
  const { data: orgRequestsData } = useGetUsersByOrgAndStatusQuery(
    {
      orgId: orgId,
      status: "Pending",
      platformId: platformData?._id,
      perPage: 10,
      page: 1,
    },
    { skip: !isPlatformDataSuccess, pollingInterval: 300000 }
  );

  return (
    <div
      className={`sidebar-container ${openSideMenu ? "open" : ""}`}
      ref={sideMenuContainerRef}
    >
      <div
        className={`sidebar ${openSideMenu ? "open" : ""}`}
        ref={sideMenuRef}
      >
        <div className="logo-details">
          <i className="logo_icon">
            <AdminPanelSettingsIcon />
          </i>
          <div className="logo_name">Admin Dashboard</div>
          <i
            className="bx bx-menu"
            id="btn"
            onClick={() => setOpenSideMenu(!openSideMenu)}
          >
            {openSideMenu ? <MenuIcon /> : <MenuIcon />}
          </i>
          <span className="tooltip">Expand</span>
        </div>
        <hr style={{ width: "100%", color: "white", marginTop: "5px" }}></hr>
        <ul className="nav-list">
          <li>
            <NavLink
              className={({ isActive }) =>
                ["admin-nav-links", isActive ? "admin-links-active" : ""].join(
                  " "
                )
              }
              to={`/dashboard`}
            >
              <i className="bx bx-grid-alt">
                <HomeIcon />
              </i>
              <span className="links_name">Home</span>
            </NavLink>
            <span className="tooltip">Home</span>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                ["admin-nav-links", isActive ? "admin-links-active" : ""].join(
                  " "
                )
              }
              to={`/admin/${orgId}/dashboard`}
            >
              <i className="bx bx-grid-alt">
                <PendingActionsIcon />
              </i>
              <span className="links_name">Pending Approvals</span>
              <span className="links_notifications">
                {orgRequestsData?.total || 0}
              </span>
            </NavLink>
            <span className="tooltip">Pending Approvals</span>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                ["admin-nav-links", isActive ? "admin-links-active" : ""].join(
                  " "
                )
              }
              to={`/admin/${orgId}/history`}
            >
              <i className="bx bx-grid-alt">
                <ManageHistoryIcon />
              </i>
              <span className="links_name">Org History</span>
            </NavLink>
            {/* <span
                className="sub-menu-icon"
                onClick={(e) => {
                  console.log("subnav flicked!");
                  e.stopPropagation();
                  setOpenOrgHistorySubMenu(!openOrgHistorySubMenu);
                }}
              >
                <KeyboardArrowDownIcon />
              </span> */}
            <span className="tooltip">Org History</span>
            {openOrgHistorySubMenu && (
              <ul className="nav-list">
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      [
                        "admin-nav-links",
                        "admin-sub-links",
                        isActive ? "admin-links-active" : "",
                      ].join(" ")
                    }
                    to={`/admin/${orgId}/history/joined`}
                  >
                    <span className="links_name">Joined History</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      [
                        "admin-nav-links",
                        "admin-sub-links",
                        isActive ? "admin-links-active" : "",
                      ].join(" ")
                    }
                    to={`/admin/${orgId}/history/revoked`}
                  >
                    <span className="links_name">Removed History</span>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          {permissions?.userType === "Owner" && (
            <li>
              <NavLink
                className={({ isActive }) =>
                  [
                    "admin-nav-links",
                    isActive ? "admin-links-active" : "",
                  ].join(" ")
                }
                to={`/admin/${orgId}/settings`}
              >
                <i className="bx bx-grid-alt">
                  <SettingsIcon />
                </i>
                <span className="links_name">Org Settings</span>
              </NavLink>
              <span className="tooltip">Org Settings</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AdminNavBar;
