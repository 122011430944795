import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { useGetOrgByIdQuery } from "state/rtkQuery/org/orgQueries";
import { useGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import { useGetUsersByOrgAndStatusQuery } from "state/rtkQuery/user/userQueries";

import JoinedTab from "./components/JoinedTab/JoinedTab";
import RejectedTab from "./components/RejectedTab/RejectedTab";
import RemovedTab from "./components/RemovedTab/RemovedTab";
import RemoveRequestedTab from "./components/RemoveRequestedTab/RemoveRequestedTab";
import RequestedTab from "./components/RequestedTab/RequestedTab";
import SideNav from "./components/SideNav/SideNav";
import {
  userJoinedHistoryMockList2,
  userRejectedHistoryMockList,
} from "./components/userHistoryMockList";

import "./OrgHistory.css";

const OrgHistory = () => {
  const [filter, setFilter] = useState("Joined");
  const { orgId } = useParams();

  // Fetching platform data, joinedUserListData, and rejectedUserListData to get the count of joined and rejected users for SideNav component
  const { data: platformData } = useGetPlatformsQuery();

  // RTK Query to get org data by orgId
  const {
    data: orgData,
    isLoading: isOrgDataLoading,
    isSuccess: isOrgDataSuccess,
    error: orgDataError,
  } = useGetOrgByIdQuery(orgId);

  const { data: joinedUserListData } = useGetUsersByOrgAndStatusQuery(
    {
      orgId: orgId,
      status: "Joined",
      platformId: platformData?._id,
      page: 1,
      perPage: 10,
    },
    { skip: !platformData?._id }
  );

  const { data: rejectedUserListData } = useGetUsersByOrgAndStatusQuery(
    {
      orgId: orgId,
      status: "Rejected",
      platformId: platformData?._id,
      page: 1,
      perPage: 10,
    },
    { skip: !platformData?._id }
  );

  const { data: removeRequestedUserListData } = useGetUsersByOrgAndStatusQuery(
    {
      orgId: orgId,
      status: "Remove-Requested",
      platformId: platformData?._id,
      page: 1,
      perPage: 10,
    },
    { skip: !platformData?._id }
  );

  const { data: removedUserListData } = useGetUsersByOrgAndStatusQuery(
    {
      orgId: orgId,
      status: "Removed",
      platformId: platformData?._id,
      page: 1,
      perPage: 10,
    },
    { skip: !platformData?._id }
  );

  const { data: requestedUserListData } = useGetUsersByOrgAndStatusQuery(
    {
      orgId: orgId,
      status: "Requested",
      platformId: platformData?._id,
      page: 1,
      perPage: 10,
    },
    { skip: !platformData?._id }
  );

  return (
    <Container
      sx={{
        maxWidth: "1300px !important",
        flex: "1",
        marginTop: "3rem",
        marginBottom: "3rem",
      }}
    >
      <Box
        sx={{
          height: 420,
          display: "grid",
          gap: "10px",
          gridTemplateColumns: "0.9fr 3fr",
          margin: "auto",
        }}
      >
        <SideNav
          filter={filter}
          setFilter={setFilter}
          joinedCount={joinedUserListData?.total}
          rejectedCount={rejectedUserListData?.total}
          removeRequestedCount={removeRequestedUserListData?.total}
          removedCount={removedUserListData?.total}
          requestedCount={requestedUserListData?.total}
        />
        {filter === "Joined" ? (
          <JoinedTab
            orgName={orgData?.name}
            userJoinedHistoryMockList={userJoinedHistoryMockList2}
          />
        ) : filter === "Rejected" ? (
          <RejectedTab
            orgName={orgData?.name}
            userRejectedHistoryMockList={userRejectedHistoryMockList}
          />
        ) : filter === "Remove-Requested" ? (
          <RemoveRequestedTab orgName={orgData?.name} />
        ) : filter === "Removed" ? (
          <RemovedTab orgName={orgData?.name} />
        ) : filter === "Requested" ? (
          <RequestedTab orgName={orgData?.name} />
        ) : null}

        {/* Main Container for Search and Table */}
      </Box>
    </Container>
  );
};

export default OrgHistory;
