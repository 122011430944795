import React, { useState } from "react";

import ReusableConfirmationModal from "./ReusableConfirmationModal";

const ReusableActions = ({ params, action }) => {
  const [showModal, setShowModal] = useState(false);

  // Close confirmation modal
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
    } else {
      setShowModal(false);
    }
  };

  // Open confirmation modal
  const openModal = () => {
    // action("reason", "otherReason");
    setShowModal(true);
    // Action to be performed on user organization status
    // setApprovalAction(action);
  };

  return (
    <div>
      {showModal && (
        <ReusableConfirmationModal
          show={showModal}
          close={handleClose}
          params={params}
          action={action}
          // approvalAction={action}
          // rejectReason={params.row.status}
        />
      )}
      <button
        className={"revoke-access-button " + params.row.status}
        disabled={params.row.status === "remove-requested"}
        onClick={() => openModal()}
      >
        {params.row.status === "joined"
          ? "Revoke Access"
          : params.row.status === "rejected" || params.row.status === "removed"
          ? "Reset Profile"
          : "Remove Requested"}
      </button>
    </div>
  );
};

export default ReusableActions;
