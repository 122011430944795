// Function to convert bytes to kilobytes, megabytes, etc.
export const convertBytes = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0) {
    return "0 Bytes";
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const convertedValue = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));

  return `${convertedValue} ${sizes[i]}`;
};
