import { createSlice } from "@reduxjs/toolkit";

// The initial state of the auth slice is an object with authToken, userAccount, and permissions properties.
const initialState = {
  authToken: null,
  accessToken: null,
  userAccount: null,
  permissions: null,
};

// The auth slice is used to maintain the authentication state of the user throughout the application.
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState: (state, action) => (state = action.payload),
  },
});

export const { setAuthState } = authSlice.actions;
