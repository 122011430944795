import React from "react";
import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import "./ConfirmationModal.css";

export const costCenters = [
  {
    id: 1,
    name: "Cost Center 1",
  },
  {
    id: 2,
    name: "Cost Center 2",
  },
  {
    id: 3,
    name: "Cost Center 3",
  },
];

const ConfirmationModal = (props) => {
  const [costCenter, setCostCenter] = React.useState("");

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={props.close}
        sx={{
          backdropFilter: "blur(1px)",
          //other styles here
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {/* Dialog Title */}
        <DialogTitle id="scroll-dialog-title" sx={{ background: "white" }}>
          Confirm Request
        </DialogTitle>
        <DialogContent
          sx={{
            maxHeight: "calc(100% - 209px)",
            maxWidth: "38vw",
            letterSpacing: "0.00938em",
            color: "black",
          }}
        >
          <p>
            {props.params?.approvalRequired === "Yes"
              ? "Are you sure you want to request access to "
              : "Are you sure you want to join "}
            <strong role="orgName">{`${props.params.orgDisplayName}?`}</strong>
          </p>
          <div className="org-description">
            {" "}
            <p>{props.params?.signup_banner}</p>
          </div>
          {props.params?.approvalRequired === "Yes" && (
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Reason for Requesting Access{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  labelid="demo-simple-select-label"
                  id="demo-simple-select"
                  multiline
                  rows={4}
                  placeholder="Please enter your request reason (minimum 25 characters)"
                  value={props.requestReason}
                  helperText={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        fontSize: "11px",
                      }}
                    >
                      <span>Minimum 25 characters</span>
                      <span>{props.requestReason.length} characters</span>
                    </div>
                  }
                  onChange={(event) =>
                    props.setRequestReason(event.target.value)
                  }
                />
              </div>
            </div>
          )}
          {/* Cost center selection... uncomment later */}
          {/* <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginBottom: "5px" }}
          >
            Select Cost Center <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            select
            SelectProps={{
              native: true,
            }}
            value={costCenter}
            onChange={(event) => setCostCenter(event.target.value)}
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "2rem",
              "& .MuiInputBase-input": { padding: "9.5px 14px" },
            }}
          >
            <option value="" disabled>
              Select Cost Center
            </option>
            {costCenters.map((costCenter) => (
              <option key={costCenter.id} value={costCenter.id}>
                {costCenter.name}
              </option>
            ))}
          </TextField> */}
          <p>
            {props.params?.approvalRequired === "Yes" ? (
              <>
                <strong>Note: </strong>Processing time may take up to 3 hours
                after an organization owner approves your request
              </>
            ) : (
              <>
                <strong>Note: </strong>Processing time for requests may take up
                to 3 hours
              </>
            )}
          </p>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Stack
            sx={{
              padding: "0.2rem 0rem",
              margin: "0 auto",
              alignContent: "center",
              justifyContent: "center",
            }}
            direction="row"
            spacing={5}
          >
            <Button
              sx={{
                backgroundColor: "white",
                border: "1px solid gray",
                letterSpacing: "0.5px",
                padding: "4px 16px",
                borderRadius: "3px",
                fontSize: "14px",
                color: "black",
              }}
              onClick={props.close}
            >
              Cancel{" "}
            </Button>
            <Button
              role="requestAccessBtn"
              sx={{
                backgroundColor: "#86BC25",
                letterSpacing: "0.5px",
                padding: "4px 16px",
                borderRadius: "3px",
                fontSize: "14px",
                color: "white",
                "&:hover": {
                  backgroundColor: "#86BC25",
                  color: "white",
                },
                "&:disabled": {
                  textTransform: "uppercase",
                },
                minWidth: "8vw",
              }}
              onClick={props.onAddOrganizationHandler}
              disabled={
                props.params?.approvalRequired === "Yes" &&
                (props.requestReason === "" || props.requestReason.length < 25)
                  ? true
                  : false
              }
            >
              {props.params?.approvalRequired === "Yes"
                ? "Request Access"
                : "Join"}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationModal;
