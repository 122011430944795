import { configureStore } from "@reduxjs/toolkit";

import { rtkQueryErrorLogger } from "./middlewares/ErrorHandlingMiddleware";
import { orgServiceApiSlice } from "./services/orgServiceApiSlice";
import { platformServiceApiSlice } from "./services/platformServiceApiSlice";
import { userServiceApiSlice } from "./services/userServiceApiSlice";
import { authSlice } from "./slices/auth/authSlice";
import { ErrorSlice } from "./slices/auth/ErrorSlice";
import { homeBannerSlice } from "./slices/auth/homeBannerSlice";
import { sessionSlice } from "./slices/sessionSlice";
import { showAllOrgsSlice } from "./slices/showAllOrgsSlice";

// The store is created by calling configureStore with a configuration object that includes the reducers and middleware.
export const store = configureStore({
  reducer: {
    [authSlice.reducerPath]: authSlice.reducer,
    [homeBannerSlice.reducerPath]: homeBannerSlice.reducer,
    [ErrorSlice.reducerPath]: ErrorSlice.reducer,
    [showAllOrgsSlice.reducerPath]: showAllOrgsSlice.reducer,
    [sessionSlice.reducerPath]: sessionSlice.reducer,
    [userServiceApiSlice.reducerPath]: userServiceApiSlice.reducer,
    [platformServiceApiSlice.reducerPath]: platformServiceApiSlice.reducer,
    [orgServiceApiSlice.reducerPath]: orgServiceApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userServiceApiSlice.middleware,
      platformServiceApiSlice.middleware,
      orgServiceApiSlice.middleware,
      rtkQueryErrorLogger
    ),
});
