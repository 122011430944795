import React, { ReactNode } from "react";
import { Button } from "@mui/material";

const LoadingButton = ({
  width,
  backgroundColor,
  color,
  height,
  border,
  onClickFunc,
  variant,
  loadingState,
  disabled = false,
  text,
  loadingText,
  children,
}: {
  width?: string | number;
  backgroundColor?: string;
  color?: string;
  height?: string | number;
  border?: string;
  onClickFunc?: () => void;
  variant?: "text" | "outlined" | "contained";
  loadingState: boolean;
  disabled?: boolean;
  text?: string;
  loadingText?: string;
  children?: ReactNode;
}) => {
  return (
    <Button
      style={{ height: height, border: border }}
      variant={variant}
      disabled={disabled || loadingState}
      sx={{
        width: width,
        backgroundColor: backgroundColor,
        color: color,
        "&:hover": {
          backgroundColor: backgroundColor,
        },
      }}
      onClick={onClickFunc}
    >
      {loadingState ? (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            gap: "10px",
            fontWeight: "normal",
          }}
        >
          <div
            className="spinner-border spinner-border-md"
            style={{
              color: "hsl(81.43deg 69.37% 43.53%)",
              width: "1rem",
              height: "1rem",
            }}
          ></div>
          <span style={{ textTransform: "capitalize" }}>{loadingText}</span>
        </div>
      ) : (
        <>
          {children} {text}
        </>
      )}
    </Button>
  );
};

export default LoadingButton;
