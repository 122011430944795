import * as React from "react";
import { useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  useCreateUserMutation,
  useUpdateUserOrgStatusMutation,
} from "state/rtkQuery/user/userMutations";

import BasicAlerts from "components/BasicAlert/BasicAlert";

import TOS from "./TOS";

import "./modalTOS.css";

function Modal(props) {
  // Local states
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [checked, setChecked] = React.useState(false);

  // Checkbox Oncheck function
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // RTK Query for updating user entry
  const [
    updateUser,
    {
      data: updatedUserData,
      isLoading: isUpdatingUser,
      isSuccess: isUpdatingUserSuccess,
      error: userUpdateError,
    },
  ] = useUpdateUserOrgStatusMutation();

  // RTK Query for creating new user entry
  const [
    createUser,
    {
      data: createdUserData,
      isLoading: isCreatingUser,
      isSuccess: isCreatingUserSuccess,
      error: createUserError,
    },
  ] = useCreateUserMutation();

  // Function to check if user record exists or not. If user exists, send updated tos_accepted, else create new user
  const handleNewClose = async () => {
    // If user record exists, update tos_accepted, else create new user
    if (props?.userData?.length !== 0) {
      let body = {
        platforms: {
          [props.platformId]: {
            tos_accepted: true,
            user_name:
              props.userData[0]?.platforms[props.platformId]?.user_name,
          },
        },
      };

      updateUser({
        userId: props.userData[0]?._id,
        body: body,
        attribute: "tos_accepted",
      });
    } else {
      const userObj = {
        email: props.email.toLowerCase(),
        platforms: {
          [props.platformId]: {
            tos_accepted: true,
            name: props.platformName,
          },
        },
      };

      // RTK query create user trigger function
      await createUser(userObj);
    }
  };

  // Close modal on successful TOS submission
  useEffect(() => {
    if (isUpdatingUserSuccess || isCreatingUserSuccess) {
      props.close();
    }
  }, [isUpdatingUserSuccess, isCreatingUserSuccess]);

  // UseState to set if bottom Scroll is reached
  const [bottom, setBottom] = React.useState(true);

  // Function To Enable Checkbox after Scroll Ends
  const handleScroll = (e) => {
    const bottom =
      parseInt(e.target.scrollHeight) -
      (parseInt(e.target.scrollTop) + parseInt(e.target.clientHeight));

    if (bottom === 1 || bottom === 0) {
      setBottom(false);
    } else {
      setBottom(true);
    }
  };

  const handleBackdropClick = (event) => {
    // these fail to keep the modal open
    event.stopPropagation();
    return false;
  };

  const descriptionElementRef = React.useRef(null);

  return (
    <div>
      {/* Dialog Content */}
      <Dialog
        open={props.show}
        onBackdropClick={handleBackdropClick}
        disableEscapeKeyDown={true}
        onClose={props.close}
        scroll={scroll}
        sx={{
          backdropFilter: "blur(5px)",
          //other styles here
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {/* Dialog Title */}
        <DialogTitle id="scroll-dialog-title" sx={{ background: "white" }}>
          DT Global Enterprise Code Repository (ECR) Rules of the Road{" "}
        </DialogTitle>

        {/* Dialog Content */}
        <DialogContent
          dividers={scroll === "paper"}
          onScroll={handleScroll}
          sx={{
            maxHeight: "calc(100% - 209px)",
            maxWidth: "600px",
            color: "black",
            letterSpacing: "0.00938em",
          }}
        >
          {/* TOS Text Content */}
          <TOS />
        </DialogContent>
        {/* Checkbox and Button */}
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Stack
            sx={{
              padding: "4px 16px",
              width: "100%",
              justifyContent: "space-between",
            }}
            direction="row"
            spacing={10}
          >
            <FormControlLabel
              value="end"
              disabled={bottom}
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="I have read and agree to the Deloitte GitHub EMU Terms of Service"
              labelPlacement="end"
            />

            <Button
              sx={{
                backgroundColor: `${!checked ? "#e9e9e9" : "#008080"}`,
                letterSpacing: "0.5px",
                padding: "0px 16px",
                borderRadius: "2px",
                fontSize: "14px",
                color: `${!checked ? "gray" : "white"}`,
                "&:hover": {
                  backgroundColor: "#006161",
                  color: "white",
                },
              }}
              onClick={handleNewClose}
              disabled={!checked}
            >
              {/* Loader on submit */}
              {isCreatingUser || isUpdatingUser ? (
                <TailSpin
                  color="hsl(81.43deg 69.37% 43.53%)"
                  height={25}
                  width={25}
                />
              ) : (
                "Accept"
              )}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Modal;
