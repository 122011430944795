// Description: This file contains the instructions data for the admin dashboard.
export const adminInstructionItems = [
  {
    name: "Find a Request to Approve/Reject",
    image: "/AdminDashboardSearchBar.png",
    description:
      "Type the requester's name or email in the search bar or locate the request manually in the list.",
  },
  {
    name: "Approving/Rejecting a Request",
    image: "/AdminDashboardActions.png",
    description: `In order to approve or reject a single request, find the action column of the corresponding row and click the 
        <span>  
            <svg 
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" 
            focusable="false" 
            aria-hidden="true" 
            viewBox="0 0 24 24" 
            data-testid="CheckCircleOutlinedIcon" 
            aria-label="Accept" 
            data-mui-internal-clone-element="true" 
            style="color: green; margin: 0px 2px; user-select: none; width: 1em; height: 1em; display: inline-block; fill: currentColor; flex-shrink: 0; transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; font-size: 1.2rem;"
            >
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"></path>
            </svg>
        </span>
        icon to approve a request or click the 
        <span>
            <svg 
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium" 
            focusable="false" 
            aria-hidden="true" 
            viewBox="0 0 24 24" 
            data-testid="HighlightOffOutlinedIcon" 
            aria-label="Reject" 
            data-mui-internal-clone-element="true" 
            style="color: red; margin: 0px 2px; user-select: none; width: 1em; height: 1em; display: inline-block; fill: currentColor; flex-shrink: 0; transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; font-size: 1.2rem;"
            >
                <path d="M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
            </svg>
        </span>
        icon to reject a request. A pop-up will appear asking you to confirm the action.`,
  },
  {
    name: "Approving/Rejecting Multiple Requests",
    image: "/AdminDashboardMultiSelect.png",
    description:
      "The admin dashboard provides a multi-select feature in order to approve/reject multiple requests at one time. To select multiple requests, click on the checkbox on the left side of the corresponding row.",
  },
  {
    name: "Approving/Rejecting Multiple Requests (cont.)",
    image: "/AdminDashboardMultiSelectActions.png",
    description: `Selecting a row's checkbox reveals two buttons at the table's bottom: 'Accept All' and 'Reject All' for the chosen requests. After clicking, a confirmation pop-up appears. 
      <p style="margin-top: 8px;"><strong>Note:</strong> Using the 'Actions' column to approve/reject a request submits an action for that request only, even if multiple requests are selected.</p>`,
  },
  {
    name: "Action Process and Timelines",
    image: "/AdminDashboardRejectDisabled.png",
    description: `After rejecting a request, the action will become disabled and the user who's request was rejected will receive an email notifying them of the rejection and the status for that organization will update to 'Rejected' status in the request portal
      <p style="margin-top: 8px;"><strong>Note:</strong> Once a user has been rejected, they must contact an organization owner/admin to raise a ticket to be manually added into the organization.</p>`,
  },
  {
    name: "Action Process and Timelines (cont.)",
    image: "/AdminDashboardApproveDisabled.png",
    description: `After approving a request, the action will become disabled and the user who's request was approved will be added to the organization and will receive an email notifying them once the process is complete.
      <p style="margin-top: 5.5px;"><strong>Note:</strong> Processing times for adding users may take up to 3 hours after approving requests.</p>`,
  },
  {
    name: "Requesting Support or Reporting an Issue",
    image: "/AdminDashboardSupport.png",
    description:
      "To request support or to report an issue, click on the 'Support Request' link at the bottom of the dashboard.",
  },
];
