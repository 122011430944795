import React from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ErrorProvider from "context/ErrorHandlingContext";
import { store } from "state/store";

import ErrorFallBack from "components/ErrorFallBack/ErrorFallBack";

import { msalConfig } from "./authConfig/authConfig";
import RequestClickTrigger from "./context/RequestClickTrigger";
import App from "./App";

// Create an instance of PublicClientApplication with the given MSAL configuration
export const msalInstance = new PublicClientApplication(msalConfig);

const container = document.querySelector("#root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <>
    <ErrorBoundary FallbackComponent={ErrorFallBack}>
      <Provider store={store}>
        <RequestClickTrigger>
          <ErrorProvider>
            <App msalInstance={msalInstance} />
          </ErrorProvider>
        </RequestClickTrigger>
      </Provider>
    </ErrorBoundary>
  </>
);
