import React from "react";

function TOS() {
  return (
    <div>
      <div id="modalContent" style={{ wordWrap: "break-word" }}>
        <h3 id="document-description">Document Description</h3>
        <p>
          The Global Rules of the Road (RoR) is a legally binding agreement that
          describes the global compliance controls and requirements (aka “Layer
          0” compliance controls) for how users interact or interface with the
          Deloitte Enterprise Code Repository service (“ECR”) hosted on the
          GitHub Enterprise Cloud (also referred in this document as ‘GHEC”)
          Platform.
        </p>
        <h3 id="service-description">Service Description</h3>
        <p>
          GitHub Enterprise Cloud (GHEC) is a powerful software development
          platform that enables source code management, coordination, sharing,
          and collaboration my multiple development teams across an entire
          organization/ The Enterprise Code Repository (ECR) refers to the
          enterprise level platform developed within GHEC to serve as a
          centralized source code management system for internal development
          projects created and maintained by Deloitte Touche Tohmatsu Limited
          (DTTL) including its member firms and its entities.
        </p>
        <h3 id="scope">Scope</h3>
        <p>
          The terms and conditions listed in this document apply to all DTTL
          users and entities that are accessing or using resources contained or
          managed in the ECR service.
        </p>
        <h3 id="supplementary-rules-of-the-road">
          Supplementary Rules of the Road
        </h3>
        <p>
          A member firm or other business entity may issue a supplementary RoR
          for users accessing or using ECR resources that are co-maintained by
          the member firm. This supplement provides additional terms and usage
          conditions that are unique to the member firm (aka “Layer 1”
          compliance controls). Users accessing these resources must acknowledge
          and agree to follow the terms and conditions contained in the
          supplement in addition to those in the Global RoR.
        </p>
        <p>
          In the event of a conflict, the terms and conditions listed in the
          Global Rules of the Road supersede those any supplementary Rules of
          the Road.
        </p>
        <p>
          If a member firm or business entity does not have a supplementary RoR,
          then the Layer 1 compliance standards defined for the Deloitte US MF
          Firm applies.{" "}
        </p>
        <h3 id="key-terms">Key Terms</h3>
        <p>This section defines several terms used throughout this document.</p>
        <p>
          <strong>Enterprise Administrators</strong>: The global ECR
          administration team responsible for maintaining and enforcement of
          Layer 0 controls and settings in the GHEC Enterprise hosting the ECR
          platform.
        </p>
        <p>
          <strong>Enterprise Managed Users (EMU)</strong>: A GHEC feature that
          allows centralized user and group management by using Identity
          Provider (IdP) groups to automate the creation of GitHub user
          accounts.
        </p>
        <p>
          <strong>Issue</strong>: GitHub’s method for tracking tasks,
          enhancements, and bugs for a project.
        </p>
        <p>
          <strong>Layer 0 Controls</strong>: Controls and settings that are
          applicable to every user. These are managed at the global level by the
          Enterprise Code Repository (ECR) Administrators.
        </p>
        <p>
          <strong>Layer 1 Controls</strong>: Optional controls and settings that
          are managed at the firm level by the Organization level ECR
          Administrators.
        </p>
        <p>
          <strong>Organization Administrators/Organization Owners</strong>: A
          member firm’s ECR administration team responsible for maintaining and
          ensuring enforcement of Layer 0 and Layer 1 controls within the GHEC
          Organization hosting their firm’s projects.
        </p>
        <p>
          <strong>Project</strong>: An environment created in GitHub to manage
          the creation and lifecycle of an application. The environment consists
          of the Git repository. metadata, and documentation associated with the
          application.
        </p>
        <p>
          <strong>Pull Request</strong>: A method of submitting contributions to
          a development project where a developer asks for changes made to be
          merged into the main or production branch of a repository.
        </p>
        <p>
          <strong>Repository</strong>: A directory which Git users to keep track
          of a project’s history and metadata. This metadata can include source
          code, documentation, and application executables.
        </p>
        <p>
          <strong>Repository Admin</strong>: A user role that grants a user full
          administrative access to a repository (except where limited by Layer 0
          and Layer 1 compliance rules). The repository owner can add additional
          repos admins as needed.
        </p>
        <p>
          <strong>Repository Owner</strong>: A user who creates a repository.
          This is usually the user who created the repository. Repo Visibility:
          A setting in GitHub that determines which users have visibility to a
          repository. The three access levels are:
        </p>
        <code>
          - Private: The repository <span className="hljs-keyword">is</span>{" "}
          visible only <span className="hljs-keyword">to</span> users who are
          specifically granted <span className="hljs-keyword">access</span>{" "}
          <span className="hljs-keyword">to</span> the repository. - Internal:
          The repository <span className="hljs-keyword">is</span> visible{" "}
          <span className="hljs-keyword">to</span>{" "}
          <span className="hljs-keyword">all</span> users{" "}
          <span className="hljs-keyword">in</span> the organization. - Public:
          The repository <span className="hljs-keyword">is</span>
          visible <span className="hljs-keyword">to</span>{" "}
          <span className="hljs-keyword">all</span> GitHub users.
        </code>
        <blockquote>
          <p>EMU Organizations do not support Public Repositories.</p>
        </blockquote>
        <p>
          <strong>Wiki</strong>: A database within each repository created for
          creating, searching, and browsing through text. Information and notes
          about a repository are stored here.
        </p>
        <p>Prohibited Data Types</p>
        <p>
          <strong>Personally Identifiable Information (PII)</strong>: Any
          information about an individual, including any information that can be
          used to distinguish or trace an individual’s identity, such as name,
          social security number, date and place of birth, mother’s maiden name,
          or biometric records; and any other information that is linkable to an
          individual, such as medical, educational, financial, and employment
          information. This includes, but is not limited to:
        </p>
        <code>
          -<span className="ruby"> Protected Health Information (PHI)</span>-
          <span className="ruby"> Sensitive and Special Handling PII</span>-
          <span className="ruby">
            {" "}
            US Federal Controlled Unclassified Information (CUI)
          </span>
          -
          <span className="ruby">
            {" "}
            Export Controlled Data <span className="hljs-keyword">
              and
            </span>{" "}
            Information
          </span>
        </code>
        <blockquote>
          <p>
            {" "}
            NOTE: The exceptions are a user’s name, Deloitte email address and
            Deloitte Azure Active Directory account which is required for access
            and user maintenance.
          </p>
        </blockquote>
        <p>
          <strong>Client-Owned Source Code and Data</strong>: ECR is intended to
          support internally developed projects only. Client-facing development
          source code and data cannot be added due to known risks of
          inadvertently exposing the intellectual property of DTTL and/or the
          client.
        </p>
        <blockquote>
          <p>
            {" "}
            NOTE: Internally developed code templates used to facilitate or
            speed up client code development for multiple clients is permitted
          </p>
        </blockquote>
        <p>
          <strong>
            Confidential information, secrets, credentials, passwords
          </strong>
          : Hardcoding or storing confidential information such as credentials,
          passwords, or other sensitive items directly in a repository is
          prohibited. Project teams are expected to use an encrypted secrets
          storage tool such as GitHub Secrets or AWS Secrets Manager for storing
          this type of information.
        </p>
        <p>Removal of Inappropriate Data</p>
        <p>
          Data that is deemed inappropriate for storage in the ECR environment
          may be removed without notice. It is the responsibility of the users
          and application teams to ensure these terms are followed and will bear
          full responsibility for any impact on an application as a result of
          such a removal.
        </p>
        <p>User Registration Requirements</p>
        <ul>
          <li>
            <p>
              Only employees or vetted contractors of a DTTL member firm or
              controlled entity with an approved user account and email address
              in the DTTL Active Directory are permitted access to ECR.
            </p>
          </li>
          <li>
            <p>
              All users must use the GitHub ECR Sign-Up Portal to create their
              Deloitte GitHub account.By using this portal, you ensure that:
            </p>
            <ul>
              <li>
                <p>
                  GitHub Enterprise automatically creates your GitHub user
                  account using the format:
                </p>
                <pre>
                  <code>
                    {" "}
                    *Deloitte email <span className="hljs-built_in">id</span> +
                    _deloitte*
                  </code>
                </pre>
                <blockquote>
                  <p>
                    Example: xyz@deloitte.com – GitHub User Account will be
                    xyz_deloitte.
                  </p>
                </blockquote>
              </li>
              <li>
                <p>
                  You are added to the organization(s) that you need access to.{" "}
                </p>
              </li>
              <li>
                <p>Your access is validated.</p>
                <blockquote>
                  <p>
                    Attempts to access an ECR organization other than through
                    the sign-up portal or other DT-approved means will result in
                    the account being suspended or removed from ECR.
                  </p>
                </blockquote>
              </li>
            </ul>
          </li>
          <li>
            <p>
              A GitHub user account may have access to more than one
              organization in the Enterprise. It is the responsibility of the
              Service and Organization Owner of each organization to regularly
              audit the organization’s member list to ensure that only
              authorized users have access to the organization.
            </p>
          </li>
          <li>
            <p>
              The GitHub account issued for ECR is for Deloitte-related work
              only. The use of this account for posting or working on any public
              or non-Deloitte project Is prohibited. Likewise, it is prohibited
              to use a public or other non-Deloitte GitHub user account to post
              or work on any Deloitte projects or code.
            </p>
          </li>
          <li>
            <p>
              All GitHub user accounts that are used within ECR are subject to
              review and may be suspended, removed, deleted and/or blocked from
              additional access in accordance with Section 3.2.5 in{" "}
              <a href="https://resources.deloitte.com/sites/worldwidetechnology/gs/pm40/Documents/SS.06_Identity_and_Access_Management.pdf">
                SS.06 Identity and Access Management
              </a>
            </p>
          </li>
        </ul>
        <h3 id="confidentiality">Confidentiality</h3>
        <ul>
          <li>
            <p>
              Data Residency: GitHub Enterprise Cloud currently maintains and
              stores its data in the United States (Virginia).
            </p>
          </li>
          <li>
            <p>
              ECR Users must review and apply the confidentiality and privacy
              requirements for their firm and/or business area including the
              details of any applicable Confidential Information Management
              Plan.
            </p>
          </li>
          <li>
            <p>
              ECR Users are expected to follow these practices to ensure
              Deloitte Intellectual Property remains confidential.
            </p>
            <ul>
              <li>
                <p>
                  Restrict information to only those who have a need to know and
                  limit the information you are sharing to only what is needed
                </p>
              </li>
              <li>
                <p>
                  Use roles to limit the type of access to only that which is
                  necessary (such as granting the ability to read but not edit
                  code where appropriate)
                </p>
              </li>
              <li>
                <p>
                  Remove access rights to a project from users when it is no
                  longer required.
                </p>
              </li>
              <li>
                <p>
                  Download information only to Deloitte devices that are
                  necessary for the project.
                </p>
              </li>
              <li>
                <p>
                  Downloading and exporting any file from GitHub Enterprise
                  Cloud onto personal or other non-Deloitte-approved devices is
                  prohibited. Uploads should only be initiated from the user’s
                  Deloitte laptop or Deloitte-managed server.{" "}
                </p>
              </li>
              <li>
                <p>
                  “Stale” repositories (for example, containing code that is no
                  longer needed, empty, or not accessed for at least a year)
                  should be reviewed to determine whether to keep, archive, or
                  delete it.
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <blockquote>
          <p>
            Periodic reviews will be performed to determine if there are any
            violations to the provided guidance. Affected users will be notified
            to correct any non-compliant issues. Users who do not correct
            non-compliant issues in a timely manner will be subjected to having
            their Deloitte GitHub user account and repository access suspended.
          </p>
        </blockquote>
        <h3 id="use-of-the-wiki-issues-and-other-project-collaboration-features">
          Use of the Wiki, Issues and Other Project Collaboration Features
        </h3>
        <ul>
          <li>
            <p>
              The wiki, issues and other project collaboration features offered
              with GitHub Enterprise Cloud is to be used only for providing
              feedback and information directly related to the software code in
              the repository.
            </p>
          </li>
          <li>
            <p>
              Posting Personally Identifiable Information (PII), secrets,
              credentials, passwords or similar sensitive or confidential
              information in the wiki, issues, or other project collaboration
              features offered with GitHub collaboration tools is prohibited.
            </p>
          </li>
        </ul>
        <h3 id="repository-access">Repository Access</h3>
        <ul>
          <li>
            <p>
              The access setting for an organization’s repositories must be set
              to “Private” to limit access to the users specifically provided
              access to the repositories unless prior approval is given for the
              repositories to be visible across an organization by the
              responsible member firm (“Internal” access setting).
            </p>
          </li>
          <li>
            <p>
              Repositories with an access level set to “Public” (viewable by all
              GitHub users) is prohibited.
            </p>
          </li>
          <li>
            <p>
              Deloitte users should only be invited to participate for the term
              of the specific task or project they are fulfilling. It is the
              responsibility of the Repository Owner/Admins to ensure users who
              longer on a project have their access reduced or removed the
              repository.
            </p>
          </li>
          <li>
            <p>
              Repository Admins should ensure that the right GitHub account is
              being before granting it access to the repository.
            </p>
          </li>
          <li>
            <p>
              Individuals who have separated from Deloitte will automatically
              lose access to GitHub Enterprise Cloud and any repository they
              previously had access to.{" "}
            </p>
          </li>
        </ul>
        <blockquote>
          <p>
            NOTE: Depending on how the account was originally configured, these
            accounts may appear on the organization’s user list with a
            “suspended” status.
          </p>
        </blockquote>
        <h3 id="inadvertently-granting-repository-access-to-a-user">
          Inadvertently Granting Repository Access to a User
        </h3>
        <p>
          If any user (Deloitte, Client, or Third Party) has been invited to
          collaborate on repository in error:
        </p>
        <code>
          - Confirm whether the user has accessed any data by checking whether
          the user’s status has changed. (The user’s role will{" "}
          <span className="hljs-keyword">show</span>{" "}
          <span className="hljs-keyword">in</span> the Manage{" "}
          <span className="hljs-keyword">Access</span>{" "}
          <span className="hljs-keyword">settings</span> page{" "}
          <span className="hljs-keyword">as</span>
          <span className="hljs-keyword">Read</span>, Write, etc.) -{" "}
          <span className="hljs-keyword">If</span> the{" "}
          <span className="hljs-keyword">user</span>’s{" "}
          <span className="hljs-keyword">status</span> has{" "}
          <span className="hljs-keyword">changed</span>, this could have
          resulted <span className="hljs-keyword">in</span> unauthorized
          disclosure <span className="hljs-keyword">or</span>{" "}
          <span className="hljs-keyword">access</span>{" "}
          <span className="hljs-keyword">to</span> Confidential Information.{" "}
          <span className="hljs-keyword">If</span> so, it needs{" "}
          <span className="hljs-keyword">to</span> be reported immediately{" "}
          <span className="hljs-keyword">as</span> a potential Confidential
          Information (CI) incident{" "}
          <span className="hljs-keyword">through</span> the appropriate Cyber
          <span className="hljs-keyword">Security</span> Incident Notification
          process your <span className="hljs-keyword">Member</span> Firm uses.
        </code>
        <h3 id="archiving-repositories-before-deletion">
          Archiving Repositories Before Deletion
        </h3>
        <p>
          It is recommended that source code repositories that are no longer
          required be “archived” first for a period to be determined by each
          member firm before they are deleted. This provides a way to recover
          the repository later if needed. The exception to this rule is for
          those repositories that are included in an “official record.”
        </p>
        <p>
          Repositories or code created in the GitHub Enterprise Cloud
          application are not themselves official records. However, if the
          content of a GitHub Repository or its artifacts is included in an
          official record, the repository and its artifacts must be archived in
          accordance with the applicable Member Firm’s Record Retention Policy.{" "}
        </p>
        <h3>GitHub Copilot Usage Guidelines</h3>
        <p>
          NOTE: "GitHub Copilot" when used in this document, refers specifically
          to "GitHub Copilot for Business," which because of its additional
          intellectual property protections, is the only approved version of
          GitHub Copilot which can be used for Deloitte projects.
        </p>
        <p>
          {" "}
          Users of GitHub Copilot for code development are expected to
          understand and follow these guidelines.{" "}
        </p>
        <p>
          NOTE: Developers must coordinate with their local Member Firm's risk,
          legal, privacy and cyber leadership to align on requirements in
          relation to usage of generative AI capability (such as GitHub Copilot)
          - including any legal regulatory or contractual considerations.
        </p>{" "}
        <ul>
          <li>
            Developers are responsible for the accuracy, completeness, and
            security of any code produced by GitHub Copilot. This includes
            making sure the code is free of security vulnerabilities, malicious
            content, or copyrighted material misuse.
          </li>{" "}
          <li>
            Do not automatically compile code. Confirm that your code editor or
            Integrated Development Environment (IDE) does not automatically
            compile or run generated code before it is reviewed.
          </li>{" "}
          <li>
            Review Suggestions carefully before accepting. Carefully review any
            Suggestions from GitHub Copilot and ensure they align with your
            purpose.
          </li>
          <li>
            Limit open project file tabs to only what is relevant. To get the
            most out of GitHub Copilot suggestions, only open those profile
            file(s) that are relevant to the project on which you are working.
            GitHub Copilot can pull content from any open project file tabs in
            your code editor/IDE; limit open files to the specific client or
            project in development.
          </li>{" "}
          <li>
            Do not include any Confidential information beyond developer code.
          </li>
          <li>
            Final comprehensive code review is required. You must perform a
            complete review of your code before putting it into production,
            including anything generated by GitHub Copilot.
          </li>
          <li>
            Continue to follow standard developer guidelines. For example,
            application development teams must follow the TOM / SSDLC process to
            have their overall proposed solution reviewed and approved prior to
            deploying their code. This should include Cyber Architecture Review,
            DPAS, SAST, DAST, SCA, etc.
          </li>
          <li>
            Developers are expected to understand and follow any additional
            guidelines and requirements for GitHub Copilot for Business provided
            by their Member Firm or Global Product Risk and Legal teams that may
            not be listed here. Developers should check the{" "}
            <a href="https://resources.deloitte.com/sites/global/Services/generative-ai/Pages/home.aspx">
              Global Generative AI Website
            </a>{" "}
            often for any updates to these requirements.
          </li>
        </ul>
        <h3 id="acceptance-of-terms-and-conditions">
          Acceptance of Terms and Conditions
        </h3>
        <p>
          By enrolling in the Deloitte Enterprise Code Repository Service, you
          are agreeing to follow these Terms of Service and the Cyber Security,
          Risk Management and Legal Policies available or listed below:
        </p>
        <ul>
          <li>
            <p>
              <a href="https://developer.deloitte.com/enterprisecoderepository/">
                Deloitte Technology Global Cybersecurity Standards
              </a>
            </p>
          </li>
          <li>
            <p>
              <a href="https://resources.deloitte.com/sites/worldwidetechnology/gs/pm40/Documents/SS.07_Secure_System_Development_Lifecycle.pdf">
                SS.07 Secure System Development Lifecycle (SSDLC)
              </a>
            </p>
          </li>
          <li>
            <p>
              Individual Member Firm Cyber Security Application and Code
              Security Standard:
            </p>
          </li>
        </ul>
        <p>
          You are required to acknowledge you have read and agree to follow
          these terms of use as well as the applicable Cyber Security Standards
          and appropriate DTTL and Member Firm polices. If you choose to not
          accept or otherwise opt-out then access will not be granted and you
          should not proceed with enrollment.
        </p>
        <h3 id="questions-or-concerns">Questions or Concerns</h3>
        <p>
          For questions or support, visit the Enterprise Code Repository
          information page{" "}
          <a href="https://developer.deloitte.com/enterprisecoderepository/">
            here
          </a>
          .
        </p>
        <p>
          Contact the Confidentiality and Privacy Concerns office of your member
          firm or business unit If you are unsure if your project can use GitHub
          or if your projects do not meet the above criteria so your use case
          may be reviewed and approved on a case by-case basis.{" "}
        </p>
      </div>
    </div>
  );
}

export default TOS;
