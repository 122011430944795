import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

const RequireAuth = () => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const isAuth = useIsAuthenticated();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuth !== undefined) {
      setLoading(false);
    }
  }, [isAuth]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div
          className="spinner-border spinner-border-md"
          style={{
            color: "hsl(81.43deg 69.37% 43.53%)",
            width: "2rem",
            height: "2rem",
          }}
        ></div>
      </div>
    ); // or any loading indicator
  }

  // Check if user is authenticated
  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/",
      }}
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
