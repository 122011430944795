import React, { useCallback, useContext, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import "./MultiSelectModal.css";

const MultiSelectModal = (props) => {
  // Multi select users email array (to be used for sending displaying selected user's emails)
  const [emailNameCollection, setEmailNameCollection] = useState([]);

  // Extracts email and name for each row from selected rows and pushes to emailNameCollection
  const extractEmailsAndNamesFromSelectedRows = useCallback(() => {
    props.selectedRows.map((currRowId) => {
      const row = props.allRows.find((row) => row.id === currRowId);
      setEmailNameCollection((prevState) => [
        ...prevState,
        { email: row["email"], name: row["name"] },
      ]);
    });
  }, [props.selectedRows]);

  // Call extractEmailsAndNamesFromSelectedRows when selectedRows changes
  useEffect(() => {
    if (props.selectedRows) {
      extractEmailsAndNamesFromSelectedRows();
    }
  }, [props?.selectedRows]);

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={props.close}
        sx={{
          backdropFilter: "blur(1px)",
          //other styles here
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {/* Dialog Title */}
        <DialogTitle id="scroll-dialog-title" sx={{ background: "white" }}>
          Confirm Action
        </DialogTitle>
        <DialogContent
          sx={{
            maxHeight: "calc(100% - 209px)",
            maxWidth: "40vw",
            minWidth: "28vw",
            color: "black",
            letterSpacing: "0.00938em",
          }}
        >
          <p>
            {props.approvalAction === "accepted"
              ? "Are you sure you want to approve join request for: "
              : "Are you sure you want to reject join request for: "}
          </p>
          <div
            style={{
              marginBottom: "30px",
              overflowY: "auto",
              maxHeight: "14rem",
              paddingRight: "5px",
            }}
          >
            {emailNameCollection?.map((email, index) => (
              <div
                key={index}
                style={{
                  listStyle: "none",
                  marginTop: "5px",
                  border: "1px solid lightgray",
                  borderRadius: "3px",
                  padding: "5px",
                  paddingLeft: "10px",
                  marginBottom: "5px",
                  backgroundColor: "whitesmoke",
                }}
              >
                <strong role="orgName">{email.name} - </strong>
                <span role="orgEmail">{email.email}</span>
              </div>
            ))}
          </div>

          <p>
            {props.approvalAction === "accepted" ? (
              <>
                <strong>Note: </strong>Processing times for adding users may
                take up to 3 hours after approving requests
              </>
            ) : (
              ""
            )}
          </p>
          {props.approvalAction === "rejected" && (
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  id="demo-simple-select-label"
                  style={{ marginBottom: "5px" }}
                >
                  Reason for Rejection <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  labelid="demo-simple-select-label"
                  id="demo-simple-select"
                  SelectDisplayProps={{
                    style: { paddingTop: 8, paddingBottom: 8 },
                  }}
                  value={props.rejectReason}
                  onChange={(event) =>
                    props.setRejectReason(event.target.value)
                  }
                >
                  <MenuItem value={"Not a member of team"}>
                    Not a member of team
                  </MenuItem>
                  <MenuItem value={"Does not have access permission"}>
                    Does not have access permission
                  </MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </div>
              {props.rejectReason === "Other" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label style={{ marginTop: "20px", marginBottom: "5px" }}>
                    Please Specify <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="request-reason-multiline"
                    className="multi-select-reject-reason-text-field"
                    placeholder={"Reason for rejection"}
                    multiline
                    rows={3}
                    value={props.otherReason}
                    onChange={(event) =>
                      props.setOtherReason(event.target.value)
                    }
                  />
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Stack
            sx={{
              padding: "0.2rem 0rem",
              margin: "0 auto",
              alignContent: "center",
              justifyContent: "center",
            }}
            direction="row"
            spacing={5}
          >
            <Button
              sx={{
                backgroundColor: "white",
                border: "1px solid gray",
                letterSpacing: "0.5px",
                padding: "4px 16px",
                borderRadius: "3px",
                fontSize: "14px",
                color: "black",
              }}
              onClick={props.close}
            >
              Cancel{" "}
            </Button>
            <Button
              role="requestAccessBtn"
              sx={{
                backgroundColor:
                  props.approvalAction === "accepted" ? "green" : "#d90000",
                letterSpacing: "0.5px",
                padding: "4px 16px",
                borderRadius: "3px",
                fontSize: "14px",
                color: "white",
                "&:hover": {
                  backgroundColor:
                    props.approvalAction === "accepted" ? "#015e01" : "#b90202",
                  color: "white",
                },
              }}
              onClick={() => props.onRequestActionHandler(props.approvalAction)}
              disabled={
                props.approvalAction === "rejected" &&
                (props.rejectReason === "" ||
                  (props.rejectReason === "Other" && props.otherReason === ""))
                  ? true
                  : false
              }
            >
              {props.approvalAction === "accepted"
                ? "Approve Request"
                : "Reject Request"}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MultiSelectModal;
