/* eslint-disable prettier/prettier */
import React from "react";

function stringAvatar(firstName, lastName) {
  return {
    initials: `${firstName ? firstName?.[0] : ""}${
      lastName ? lastName?.[0] : ""
    }`,
  };
}

const AvatarRandomColor = ({ firstName, lastName, color }) => {
  const { initials } = stringAvatar(firstName, lastName);

  return (
    <div
      style={{
        backgroundColor: color,
        color: "white",
        minWidth: "40px",
        maxWidth: "40px",
        height: "40px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1em",
      }}
    >
      {initials}
    </div>
  );
};

export default AvatarRandomColor;
