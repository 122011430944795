export const ErrorStatusCodeMapper = (endpoint) => ({
  400: `Bad Request for ${endpoint}. Please check your input and try again.`,
  401: "Your session has expired. Please login again.",
  403: `Forbidden for ${endpoint}. You do not have permission to access this resource.`,
  404: `Not Found for ${endpoint}. The resource you are looking for does not exist.`,
  405: `Method Not Allowed for ${endpoint}. Please check your input and try again.`,
  406: `Not Acceptable for ${endpoint}. Please check your input and try again.`,
  408: `Request Timeout for ${endpoint}. Please try again later.`,
  409: `Conflict for ${endpoint}. Please check your input and try again.`,
  500: `Internal Server Error for ${endpoint}. Please try again later.`,
  FETCH_ERROR: `Failed to fetch data for ${endpoint}. Please try again later.`,
  UNKNOWN_ERROR: `An unknown error occurred for ${endpoint}. Please try again later.`,
  PARSING_ERROR: `Failed to parse data for ${endpoint}. Please try again later.`,
});
