import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Info } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { createTheme, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import "bootstrap/dist/css/bootstrap.min.css";
import "./tableProgress.css";

function InitiatedProgress({ params }) {
  const [stepSet, setStepSet] = useState();
  const setStepLabel = (step) => {
    switch (step) {
      case "Non-Member":
        setStepSet(1);
        break;
      case "In-Progress":
        setStepSet(2);
        break;
      case "Pending":
        setStepSet(2);
        break;
      case "Joined":
        setStepSet(3);
        break;
      case "Failed":
        setStepSet(4);
        break;
      case "Rejected":
        setStepSet(4);
        break;
      case "Removed":
        setStepSet(4);
        break;

      // case undefined:
      //   setStepSet("error");
      //   break;
      default:
        return 1;
    }
  };

  useEffect(() => {
    setStepLabel(params?.row?.status);
  }, [params?.row]);

  // Theme for tooltip
  const InfoIconToolTipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "0.9rem", // Adjust this value to your needs
            padding: "8px 12px",
          },
        },
      },
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "300px", marginRight: "12px" }}>
        {stepSet === 4 ? (
          <Tooltip title={params?.row?.status}>
            <ProgressBar>
              <ProgressBar
                style={{ backgroundColor: "#FF0000", height: "4px" }}
                now={100}
                key={1}
                label="Failed"
                visuallyHidden
              />
            </ProgressBar>
          </Tooltip>
        ) : stepSet === 3 ? (
          <Tooltip title={params?.row?.status}>
            <ProgressBar>
              <ProgressBar
                style={{ backgroundColor: "#43B02A", height: "4px" }}
                now={100}
                key={1}
                label="Failed"
                visuallyHidden
              />
            </ProgressBar>
          </Tooltip>
        ) : stepSet === 2 ? (
          <Tooltip title={params?.row?.status}>
            <ProgressBar>
              <ProgressBar
                style={{ backgroundColor: "#ED8B00" }}
                now={66.6}
                key={2}
                label="In-Progress"
                visuallyHidden
              />
            </ProgressBar>
          </Tooltip>
        ) : (
          <Tooltip title={params?.row?.status}>
            <ProgressBar>
              <ProgressBar
                style={{ backgroundColor: "#007CB0", height: "4px" }}
                now={33.3}
                key={1}
                label="Non-Member"
                visuallyHidden
              />
            </ProgressBar>
          </Tooltip>
        )}
      </Box>
      {stepSet === 2 && (
        <ThemeProvider theme={InfoIconToolTipTheme}>
          <Tooltip
            title={
              params?.row?.status === "Pending"
                ? "Your access request is currently awaiting approval from the organization owners. To expedite the approval process, Please reach out to the respective org admins for further assistance - https://developer.deloitte.com/ecr-docs/globalorgs "
                : "Your access request has been approved and you can expect to gain access within the next 2-3 hours following the approval. Status will change to 'Joined' once you have received access."
            }
            sx={{
              color: "#008080",
              cursor: "pointer",
              fontSize: "20px",
            }}
            arrow
          >
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </ThemeProvider>
      )}
    </div>
  );
}

export default InitiatedProgress;
