import { createSlice } from "@reduxjs/toolkit";

const initialState = { recommendedOrgsBanner: true, deloitteUSBanner: true };

export const homeBannerSlice = createSlice({
  name: "homeBanner",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      if (action.payload.recommendedOrgsBanner !== undefined) {
        state.recommendedOrgsBanner = action.payload.recommendedOrgsBanner;
      }
      if (action.payload.deloitteUSBanner !== undefined) {
        state.deloitteUSBanner = action.payload.deloitteUSBanner;
      }
    },
  },
});

export const { setOpen } = homeBannerSlice.actions;
