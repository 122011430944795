import React, { createContext, useState } from "react";

export const ErrorContext = createContext();

const ErrorProvider = (props) => {
  const [errorStack, setErrorStack] = useState({});

  const pushError = (errorKey, error) => {
    setErrorStack((prevState) => ({
      ...prevState,
      [errorKey]: error,
    }));

    setTimeout(() => {
      removeError(errorKey);
    }, 60 * 1000);
  };

  const removeError = (errorKey) => {
    setErrorStack((prevState) => {
      const updatedObj = { ...prevState };
      delete updatedObj[errorKey];
      return updatedObj;
    });
  };

  return (
    <ErrorContext.Provider value={{ errorStack, pushError, removeError }}>
      {" "}
      {props.children}
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;
