import React from "react";

function UserCountDisplay({ backgroundColor, count }) {
  // Function to convert number to K, M, B, etc.
  const nFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
    ];

    // Regular expression to remove trailing zeros
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;

    // Find the item in the lookup array
    const item = lookup.findLast((item) => num >= item.value);

    // Return the formatted number
    return item
      ? (num / item.value)
          .toFixed(digits)
          .replace(regexp, "")
          .concat(item.symbol)
      : "0";
  };

  return (
    <div>
      <span
        className="numberCircleUserCount"
        style={{
          backgroundColor: backgroundColor,
          fontSize: "10px",
          color: "#1f2328",
          fontWeight: "bold",
        }}
      >
        {nFormatter(count, 1)}
      </span>
    </div>
  );
}

export default UserCountDisplay;
