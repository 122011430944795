import React, { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { NavigationClient } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import CryptoJS from "crypto-js";

import AdminLayout from "./components/AdminLayout/AdminLayout";
import Layout from "./components/Layout/Layout";
import NavBar from "./components/navigation/NavBar";
import NotFound from "./components/NotFound/NotFound";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import RequireRoles from "./components/RequireRoles/RequireRoles";
import AdminDashboard from "./pages/admin/AdminDashboard";
import Home from "./pages/home/home";
import InactiveLogout from "./pages/home/InactiveLogout/InactiveLogout";
import HomeDashboard from "./pages/homeDashboard/HomeDashboard";
import OrgHistory from "./pages/OrgHistory/OrgHistory";
import OrgSettings from "./pages/orgSettings/OrgSettings";
import { setAuthState } from "./state/slices/auth/authSlice";

import "./App.css";

class CustomNavigationClient extends NavigationClient {
  constructor(navigate) {
    super();
    this.navigate = navigate; // Passed in from useNavigate hook provided by react-router-dom;
  }
  // This function will be called anytime msal needs to navigate from one page in your application to another
  async navigateInternal(url, options) {
    // url will be absolute, you will need to parse out the relative path to provide to the history API
    const relativePath = url.replace(window.location.origin, "/");
    if (options.noHistory) {
      this.navigate(relativePath, { replace: true });
    } else {
      this.navigate(relativePath);
    }

    return false;
  }
}

function AppRoutes({ msalInstance }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Set the custom navigation client on the msal instance
  const navigationClient = new CustomNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);

  // Get encrypted auth token from localstorage, decrypt, and store in auth slice to rehydrate state on refresh
  // This is a temporary solution until we implement refresh tokens
  const authToken = useSelector((state) => state.auth.authToken);
  if (!authToken && localStorage.getItem("authStateEncrypted")) {
    const decryptedAuthToken = CryptoJS.AES.decrypt(
      localStorage.getItem("authStateEncrypted"),
      "someKey"
    ).toString(CryptoJS.enc.Utf8);

    dispatch(setAuthState(JSON.parse(decryptedAuthToken)));
  }

  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Home />} />

      {/* Protected Routes */}
      <Route path="/*" element={<RequireAuth />}>
        <Route element={<Layout />}>
          <Route path="dashboard" element={<HomeDashboard />} />

          {/* Role-Based Protected Routes */}
          <Route
            path="admin/:orgId"
            element={<RequireRoles allowedRoles={["Owner", "Approver"]} />}
          >
            <Route element={<AdminLayout />}>
              {/* Owner and Approver Routes */}
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="history" element={<OrgHistory />} />

              {/* Owner only Routes */}
              <Route
                path=""
                element={<RequireRoles allowedRoles={["Owner"]} />}
              >
                <Route path="settings" element={<OrgSettings />} />
              </Route>
            </Route>
          </Route>

          {/* Catch all */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>

      {/* Catch all */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function App({ msalInstance }) {
  // It's important that the Router component is above the Example component because you'll need to use the useHistory hook before rendering MsalProvider

  return (
    <Router>
      <MsalProvider instance={msalInstance}>
        <InactiveLogout>
          <AppRoutes msalInstance={msalInstance} />
        </InactiveLogout>
      </MsalProvider>
    </Router>
  );
}

export default App;
