import { isRejectedWithValue } from "@reduxjs/toolkit";
import { addError, removeError } from "state/slices/auth/ErrorSlice";
import { setSessionExpired } from "state/slices/sessionSlice";
import { ErrorStatusCodeMapper } from "utils/ErrorStatusCodeMapper";
import { msalLogout } from "utils/msalLogout";

/**
 * Check for errors and pushes to error slice
 */
export const rtkQueryErrorLogger = (store) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  const conditionError = "ConditionError";
  /**If we got actual rejection from the APIs than store error in error slice */

  if (
    isRejectedWithValue(action) ||
    (action.type.endsWith("/rejected") && action.error.name !== conditionError)
  ) {
    let message = action?.payload?.data?.error;

    if (message === undefined) {
      message = action?.payload?.data?.detail;
    }

    // If the error is 401, auth token is expired, so we need to logout the user
    if (action?.payload?.status === 401 || message === undefined) {
      if (action?.payload?.status === 401) {
        store.dispatch(setSessionExpired(true));
      }
      message = ErrorStatusCodeMapper(action.meta?.arg?.endpointName)[
        action?.payload?.status
      ];
    }

    store.dispatch(
      addError({
        url: action.meta?.arg?.endpointName,
        message: message,
        status: action?.payload?.status,
      })
    );

    setTimeout(() => {
      store.dispatch(removeError(action.meta?.arg?.endpointName));
    }, 60000);
  }

  return next(action);
};
