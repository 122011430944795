import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { json, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import { useUpdateUserOrgStatusMutation } from "state/rtkQuery/user/userMutations";
import { useGetUsersByOrgAndStatusQuery } from "state/rtkQuery/user/userQueries";
import { EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_APPROVER_VIEW } from "utils/constants";
import { generateRandomColor } from "utils/generateRandomColor";

import AvatarRandomColor from "../ReusableDataGrid/AvatarRandomColor";
import ReusableActions from "../ReusableDataGrid/ReusableActions";
import ReusableDataGrid from "../ReusableDataGrid/ReusableDataGrid";

const RemoveRequestedTab = ({ orgName }) => {
  const [columns, setColumns] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const { orgId } = useParams();
  const [orgPermissions, setOrgPermissions] = useState();

  const permissions = useSelector((state) => state.auth.permissions);

  // Get user's permissions for the organization
  useEffect(() => {
    if (orgId && permissions) {
      setOrgPermissions(
        permissions?.filter((permission) => permission.orgId === orgId)
      );
    }
  }, [orgId, permissions]);

  const { data: platformData, isFetching: isPlatformDataLoading } =
    useGetPlatformsQuery();

  const {
    data: removeRequestedUserListData,
    isFetching: isRemoveRequestedUserListDataLoading,
  } = useGetUsersByOrgAndStatusQuery(
    {
      orgId: orgId,
      status: "Remove-Requested",
      platformId: platformData?._id,
      page: currentPage,
      perPage: 25,
      email: query === "" ? undefined : query,
    },
    { skip: !platformData?._id }
  );

  // Set datagrid rows
  // Combines both joined and remove requested users into a single list
  useEffect(() => {
    if (removeRequestedUserListData) {
      setUpdatedRows(removeRequestedUserListData?.users);
    }
  }, [removeRequestedUserListData]);

  // Logged in user's email
  const userEmail = useSelector((state) => state.auth.userAccount?.username);

  // Set columns
  useEffect(() => {
    if (updatedRows) {
      setColumns([
        {
          field: "name",
          headerName: `Showing ${(currentPage - 1) * 25 + 1} - ${
            (currentPage - 1) * 25 + updatedRows?.length
          } of ${removeRequestedUserListData?.total || 0} Users`,
          headerClassName: "history-tab-totalCount-header-approver-view",
          headerAlign: "left",
          align: "left",
          width: 350,
          renderCell: (params) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                paddingLeft: "30px",
              }}
            >
              <AvatarRandomColor
                firstName={params.row.firstName}
                lastName={params.row.lastName}
                color={params.row.color}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "20px",
                }}
              >
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>{params.row.name}</span>
                  }
                  placement="top-start"
                  arrow
                >
                  <span
                    className="table-history-userEmailJoined-cell"
                    style={{ fontWeight: "600", fontSize: "14px" }}
                  >
                    {params.row.name}
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "12px" }}>{params.row.email}</span>
                  }
                  arrow
                  placement="bottom-start"
                >
                  {params.row.email.length >
                  EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_APPROVER_VIEW ? (
                    <div>
                      <span
                        className="table-cell-trucate-reason"
                        style={{ paddingLeft: "0", fontSize: "13px" }}
                      >
                        {params.row.email?.slice(
                          0,
                          EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_APPROVER_VIEW
                        ) + "..."}
                      </span>{" "}
                    </div>
                  ) : (
                    <span style={{ fontSize: "13px" }}>{params.row.email}</span>
                  )}
                </Tooltip>
              </div>
            </div>
          ),
        },
        {
          field: "JoinedDate",
          headerName: "Date of Request",
          headerClassName: "date-joined-table-header",
          headerAlign: "center",
          align: "center",
          width: 240,
          renderCell: (params) => (
            <span className="history-tab-joinedDate-cell ">
              {params.row.date}
            </span>
          ),
        },
        {
          field: "removedBy",
          headerName: "Remove Requested By",
          headerClassName: "orgName-column-header",
          headerAlign: "center",
          align: "center",
          width: 350,
          renderCell: (params) => (
            <Tooltip
              title={<p style={{ fontSize: "12px" }}>{params.row.changedBy}</p>}
            >
              <span className="table-cell-history">{params.row.changedBy}</span>
            </Tooltip>
          ),
        },
      ]);
    }
  }, [updatedRows]);

  return (
    <div>
      <ReusableDataGrid
        columns={columns}
        rows={updatedRows}
        orgName={orgName}
        viewType={"Removals Requested History"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setQuery={setQuery}
        query={query}
        checkboxSelection={false}
        totalRecords={removeRequestedUserListData?.total || 0}
        isLoading={
          isRemoveRequestedUserListDataLoading || isPlatformDataLoading
        }
        tabDescription={
          "Users in Removals Requested have had a request made to have their access revoked from the organization and are in the Active Directory sync process"
        }
      />
    </div>
  );
};

export default RemoveRequestedTab;
