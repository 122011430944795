import React, { useContext, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box } from "@mui/system";
import { useUpdateUserOrgStatusMutation } from "state/rtkQuery/user/userMutations";

import AdminConfirmationModal from "../AdminConfirmationModal/AdminConfirmationModal";

import "./AdminUserActions.css";

const AdminUserActions = ({ params, orgData, platformId, userEmail }) => {
  // Local States
  const [showModal, setShowModal] = useState(false);
  const [approvalAction, setApprovalAction] = useState();
  const [rejectReason, setRejectReason] = useState("");
  const [otherReason, setOtherReason] = useState("");

  // RTK Query for updating user organization status
  const [updateUserOrgStatus, { isLoading: isUpdateUserOrgStatusLoading }] =
    useUpdateUserOrgStatusMutation();

  // Update user organization status api call function and close confirmation modal
  const onAddUserToOrganizationHandler = (action) => {
    handleClose();
    updateUserOrgStatus({
      userId: params.row.id,
      body: {
        platforms: {
          [platformId]: {
            organizations: {
              [orgData._id]: {
                membership_status:
                  action === "rejected" ? "Rejected" : "Requested",
                reason: rejectReason === "Other" ? otherReason : rejectReason,
                changed_by: userEmail.toLowerCase(),
              },
            },
          },
        },
      },
      attribute: "membership_status",
    });
  };

  // Close confirmation modal
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
    } else {
      setShowModal(false);
      // Reset reject reason and other reason
      setRejectReason("");
      setOtherReason("");
    }
  };

  // Open confirmation modal
  const openModal = (action) => {
    setShowModal(true);
    // Action to be performed on user organization status
    setApprovalAction(action);
  };

  return (
    <div>
      {showModal && (
        <AdminConfirmationModal
          show={showModal}
          close={handleClose}
          params={params.row}
          onRequestActionHandler={onAddUserToOrganizationHandler}
          approvalAction={approvalAction}
          rejectReason={rejectReason}
          setRejectReason={setRejectReason}
          otherReason={otherReason}
          setOtherReason={setOtherReason}
        />
      )}
      <Box sx={{ position: "relative", display: "flex" }}>
        {isUpdateUserOrgStatusLoading ? (
          <TailSpin
            color="hsl(81.43deg 69.37% 43.53%)"
            height={25}
            width={25}
          />
        ) : (
          <>
            {params.row.status === "pending" ||
            params.row.status === "accepted" ? (
              <Tooltip
                title={params.row.status === "accepted" ? "Accepted" : "Accept"}
              >
                <CheckCircleOutlinedIcon
                  className="approveButton"
                  style={{
                    color:
                      params.row.status === "accepted" ? "#b5b5b5" : "green",
                    margin: "8px",
                  }}
                  onClick={() =>
                    params.row.status === "accepted"
                      ? {}
                      : openModal("accepted")
                  }
                />
              </Tooltip>
            ) : (
              <></>
            )}
            {params.row.status === "pending" ? (
              <div style={{ margin: "4px 5px", fontSize: "20px" }}>|</div>
            ) : (
              <></>
            )}
            {params.row.status === "pending" ||
            params.row.status === "rejected" ? (
              <Tooltip
                title={params.row.status === "rejected" ? "Rejected" : "Reject"}
              >
                <HighlightOffOutlinedIcon
                  className="rejectButton"
                  style={{
                    color: params.row.status === "rejected" ? "#b5b5b5" : "red",
                    margin: "8px 10px",
                  }}
                  onClick={() =>
                    params.row.status === "rejected"
                      ? {}
                      : openModal("rejected")
                  }
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </>
        )}
      </Box>
    </div>
  );
};

export default AdminUserActions;
