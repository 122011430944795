import { orgServiceApiSlice } from "state/services/orgServiceApiSlice";

// The `orgServiceApiSlice` object is used to generate hooks that can be used to make API calls to the org service.
const orgServiceApiQueries = orgServiceApiSlice.injectEndpoints({
  // The `endpoints` object is used to define the API endpoints for the org service.
  endpoints: (builder) => ({
    // The `getOrgById` query is used to retrieve an organization by its ID.
    getOrgById: builder.query({
      query: (orgId) => ({
        url: `/organizations/${orgId}`,
      }),
      transformResponse: (responseData) => {
        return responseData;
      },
      providesTags: ["Organization"],
    }),
  }),
});

export const { useGetOrgByIdQuery } = orgServiceApiQueries;
