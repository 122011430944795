import React, { createContext, useState } from "react";

export const RequestClickTriggerContext = createContext();

const RequestClickTrigger = (props) => {
  const [reqTrigger, setReqTrigger] = useState(false);
  return (
    <RequestClickTriggerContext.Provider value={{ reqTrigger, setReqTrigger }}>
      {" "}
      {props.children}
    </RequestClickTriggerContext.Provider>
  );
};
export default RequestClickTrigger;
