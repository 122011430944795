import React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "@mui/material";

import AdminNavBar from "components/AdminNavBar/AdminNavBar";
import Errors from "components/Errors/Errors";

const AdminLayout = () => {
  return (
    <div style={{ display: "flex" }}>
      <AdminNavBar />
      <div style={{ flex: "1" }}>
        <Errors />
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
