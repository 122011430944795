import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { adminInstructionItems } from "pages/admin/data/adminInstructions";
import { instructionItems } from "pages/homeDashboard/data/portalInstructionData";
import { useGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import { useGetUserQuery } from "state/rtkQuery/user/userQueries";
import { generateRandomColor } from "utils/generateRandomColor";

import InstructionModal from "components/InstructionModal/InstructionModal";

import { SignInButton } from "./login/SignInButton";
import { SignOutButton } from "./login/SignOutButton";

import "./NavBar.css";

const NavBar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [color, setColor] = React.useState("#999999");
  const [isAvatarLoading, setIsAvatarLoading] = React.useState(true);

  // Dark theme for the navbar
  // Currently not in use
  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
    },
  });

  // Provides user's name to create initials for the user's avatar
  const authState = useSelector((state) => state.auth);
  const email = useSelector((state) => state.auth.userAccount.username);
  // RTK Query for getting platform data
  const { data: platformData, isSuccess: isPlatformDataSuccess } =
    useGetPlatformsQuery();

  // RTK Query for getting user entry by email
  const { data: userData, isError: userDataError } = useGetUserQuery(
    { email: email },
    { skip: !isPlatformDataSuccess && !platformData }
  );

  useEffect(() => {
    if (userData && userData?.length !== 0) {
      setColor(generateRandomColor(userData[0]?._id));
      setIsAvatarLoading(false);
    } else if (userDataError) {
      setIsAvatarLoading(false);
    }
  }, [userData, userDataError]);

  // Function to create initials for the user's avatar from the user's name
  function stringAvatar(firstName, lastName) {
    return {
      sx: {
        bgcolor: color,
      },
      children: `${firstName ? firstName?.[0] : ""}${
        lastName ? lastName?.[0] : ""
      }`,
    };
  }

  // Instruction modal state
  const [showInstructionModal, setShowInstructionModal] = React.useState(false);

  // Handles closing instruction modal
  const handleInstructionClose = (event, reason) => {
    setShowInstructionModal(false);
  };

  // Opens instruction modal
  const openInstructionModal = () => {
    setShowInstructionModal(true);
  };

  return (
    <div>
      <ThemeProvider theme={darkTheme}>
        <AppBar
          position="static"
          sx={{ height: "60px" }}
          data-testid="background"
        >
          {showInstructionModal &&
            (location?.pathname === "/dashboard" ? (
              <InstructionModal
                show={showInstructionModal}
                close={handleInstructionClose}
                instructionEntries={instructionItems}
                title={"Instructions for Request Dashboard"}
              />
            ) : (
              <InstructionModal
                show={showInstructionModal}
                close={handleInstructionClose}
                instructionEntries={adminInstructionItems}
                title={"Instructions for Admin Dashboard"}
              />
            ))}
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => navigate("/dashboard")}
            >
              <img
                src="/circleLogo350.png"
                width="40"
                height="40"
                alt="Deloitte logo"
              ></img>
            </IconButton>

            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, fontFamily: "Open Sans" }}
            >
              {authState.authToken
                ? "DT-Global Enterprise Code Repository (ECR)"
                : null}
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {authState?.authToken && (
                <Button>
                  <a
                    href="https://github.com/enterprises/deloitte-global/sso"
                    target="_blank"
                    className="github-signIn-link"
                    rel="noreferrer"
                  >
                    GitHub Sign-In
                  </a>
                </Button>
              )}
              {authState?.authToken && (
                <Button onClick={() => setShowInstructionModal(true)}>
                  <a className="github-signIn-link">User Guide</a>
                </Button>
              )}
              {authState?.userAccount && !isAvatarLoading ? (
                <Avatar
                  {...stringAvatar(
                    authState.userAccount?.givenName,
                    authState.userAccount?.surname
                  )}
                  size="sm"
                />
              ) : null}
              {authState?.authToken ? <SignOutButton /> : null}
            </Stack>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      {props.children}
    </div>
  );
};

export default NavBar;
