import { createSlice } from "@reduxjs/toolkit";

// The initial state of the auth slice is an object with authToken, userAccount, and permissions properties.
const initialState = {
  showAllOrgs: false,
};

// The auth slice is used to maintain the authentication state of the user throughout the application.
export const showAllOrgsSlice = createSlice({
  name: "showAllOrgs",
  initialState,
  reducers: {
    setShowAllOrgsToggle: (state, action) => {
      state.showAllOrgs = action.payload;
    },
  },
});

export const { setShowAllOrgsToggle } = showAllOrgsSlice.actions;
