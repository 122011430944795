import React, { useContext } from "react";
import Carousel from "react-material-ui-carousel";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import "./InstructionModal.css";

const InstructionModal = (props) => {
  const Item = (props) => {
    return (
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <img
          src={props.item.image}
          style={{ width: "90%", aspectRatio: "16/6", objectFit: "contain" }}
        />
        <div
          style={{
            padding: "10px 0px",
            marginTop: "12.5px",
          }}
        >
          <div style={{ marginBottom: "12.5px" }}>
            <p>
              <strong>{props.item.name}</strong>
            </p>
          </div>
          <div
            className="instruction-text-content"
            style={{ maxHeight: "135px", overflowY: "auto", padding: "0 10px" }}
          >
            <p
              style={{ fontSize: "13px" }}
              dangerouslySetInnerHTML={{ __html: props.item.description }}
            ></p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={props.close}
        sx={{
          backdropFilter: "blur(1px)",
          //other styles here
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {/* Dialog Title */}
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            background: "white",
            color: "gray",
            fontSize: "14px",
            paddingRight: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              fontSize: "16px",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "", color: "", fontSize: "15px" }}>
              {props.title}
            </div>
            <div
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                marginBottom: "5px",
              }}
              onClick={props.close}
            >
              <CloseIcon className="instruction-close-icon" />
            </div>
          </div>
        </DialogTitle>
        {/* Modal Carousel */}
        <DialogContent
          sx={{
            maxHeight: "calc(100% - 50px)",
            maxWidth: "800px",
            minWidth: "30rem",
          }}
        >
          <Carousel
            sx={{ minHeight: "28rem" }}
            NextIcon={
              <Button
                sx={{
                  backgroundColor: "#008080",
                  letterSpacing: "0.5px",
                  padding: "4px 30px",
                  borderRadius: "2px",
                  fontSize: "12px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#006161",
                    color: "white",
                  },
                }}
              >
                Next{" "}
              </Button>
            }
            PrevIcon={
              <Button
                sx={{
                  backgroundColor: "white",
                  border: "1px solid gray",
                  letterSpacing: "0.5px",
                  padding: "4px 30px",
                  borderRadius: "2px",
                  fontSize: "12px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#c22929",
                    color: "white",
                  },
                }}
              >
                Back{" "}
              </Button>
            }
            fullHeightHover={false}
            navButtonsProps={{
              style: {
                backgroundColor: "unset",
                borderRadius: 0,
                opacity: "unset",
              },
            }}
            navButtonsWrapperProps={{
              style: {
                height: "unset",
                position: "absolute",
                bottom: "0px",
                top: "unset",
              },
            }}
            indicatorContainerProps={{
              style: {
                position: "relative",
                top: "unset",
                bottom: "0px",
              },
            }}
            indicatorIconButtonProps={{
              style: {
                padding: "3px",
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: "rgb(11 153 211)",
              },
            }}
            navButtonsAlwaysVisible={true}
            autoPlay={false}
          >
            {props.instructionEntries?.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Carousel>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InstructionModal;
