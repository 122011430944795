import React from "react";
import { useSelector } from "react-redux";
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Box, Button, Container } from "@mui/material";

// Define a type for the permissions
interface PermissionsType {
  orgId: string;
  userType: string;
}

interface UserAccountType {
  homeAccountId: string;
  environment: string;
  tenantId: string;
  username: string;
  localAccountId: string;
  name: string;
  idTokenClaims: {
    aud: string;
    iss: string;
    iat: number;
    nbf: number;
    exp: number;
    name: string;
    nonce: string;
    oid: string;
    preferred_username: string;
    rh: string;
    sub: string;
    tid: string;
    uti: string;
    ver: string;
  };
  givenName: string;
  surname: string;
}

// Define a type for the auth state
interface AuthStateType {
  authToken: string;
  accessToken: string;
  userAccount: UserAccountType;
  permissions: PermissionsType[];
}

const RequireRoles = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state: { auth: AuthStateType }) => state.auth);
  const { orgId } = useParams();

  const redirectToHomeDashboard = () => {
    navigate("/dashboard");
  };

  const unauthorizedAccessMessage = (allowedRoles: string[]) => {
    return allowedRoles.map((role, index) => {
      if (index === allowedRoles.length - 1 && allowedRoles.length > 1) {
        return <span key={index}>and {role} </span>;
      } else if (index === allowedRoles.length - 1) {
        return <span key={index}>{role} </span>;
      } else {
        return <span key={index}>{role}, </span>;
      }
    });
  };

  // Check if user has the required roles for the selected organization admin page
  return auth?.permissions?.find(
    (obj: PermissionsType) =>
      obj?.orgId === orgId && allowedRoles?.includes(obj?.userType)
  ) ? (
    <Outlet />
  ) : auth?.authToken ? (
    <Container>
      <Box
        sx={{
          height: 420,
          marginTop: "4rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <h3 style={{ fontWeight: "bold" }}>Unauthorized Access</h3>
        <p style={{ marginTop: "1rem", marginBottom: "3rem" }}>
          You are not authorized to access this page. Only{" "}
          {unauthorizedAccessMessage(allowedRoles)} users have access.
        </p>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#008080",
            "&:hover": {
              backgroundColor: "#008080",
            },
          }}
          onClick={() => redirectToHomeDashboard()}
        >
          Go to home dashboard
        </Button>
      </Box>
    </Container>
  ) : (
    <Navigate to={"/"} state={{ from: location }} replace />
  );
};

export default RequireRoles;
