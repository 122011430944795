// Purpose: Function to generate a random color and invert it if it is too light.
export const generateRandomColor = (id) => {
  // Generate a color for user based on user ID
  let red = 0;
  let green = 0;
  let blue = 0;
  for (let i = 0; i < id.length; i++) {
    red = (red + (id.charCodeAt(i) << 3) * 17) % 256;
    green = (green + (id.charCodeAt(i) << 2) * 19) % 256;
    blue = (blue + (id.charCodeAt(i) << 1) * 23) % 256;
  }

  // Calculate the brightness of the color using the formula for luminance
  const brightness = 0.2126 * red + 0.7152 * green + 0.0722 * blue;

  // Set a threshold for the brightness to determine if the color is dark or light (Can be adjusted)
  const threshold = 128;
  const isDark = brightness < threshold;

  // Convert the RGB values to a hexadecimal string
  const color =
    "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1);

  // Return the color if it is dark enough, otherwise return the inverted color
  return isDark ? color : invertColor(color);
};

// Function to invert the color
const invertColor = (hex) => {
  // Remove the hash sign from the beginning of the hex color
  const red = parseInt(hex.substring(1, 3), 16);
  const green = parseInt(hex.substring(3, 5), 16);
  const blue = parseInt(hex.substring(5, 7), 16);

  // Invert the red, green, and blue values
  const invertedRed = 255 - red;
  const invertedGreen = 255 - green;
  const invertedBlue = 255 - blue;

  // Convert the inverted red, green, and blue values to a hexadecimal string
  const invertedColor =
    "#" +
    ((1 << 24) + (invertedRed << 16) + (invertedGreen << 8) + invertedBlue)
      .toString(16)
      .slice(1);

  return invertedColor;
};
