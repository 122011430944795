import { orgServiceApiSlice } from "state/services/orgServiceApiSlice";

// The `orgServiceApiMutations` object provides hooks that allow you to make mutations to the org service API.
export const orgServiceApiMutations = orgServiceApiSlice.injectEndpoints({
  // The `endpoints` object is used to define the API endpoints for the org service.
  endpoints: (builder) => ({
    // The `updateOrg` mutation is used to update an organization entry.
    updateOrg: builder.mutation({
      query: ({ orgId, body }) => ({
        url: `/organizations/${orgId}`,
        method: "PATCH",
        body: body,
      }),
      transformResponse: (responseData) => {
        return responseData;
      },
      // The `invalidatesTags` array is used to specify the tags that should be invalidated when the mutation is called.
      // This will cause any queries that use these tags to be refetched.
      invalidatesTags: ["Organization"],
    }),
  }),
});

export const { useUpdateOrgMutation } = orgServiceApiMutations;
