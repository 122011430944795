// Description: This file contains the data for the home dashboard instructions modal.
export const instructionItems = [
  {
    name: "Find an Organization to join",
    image: "/SearchBarSS.png",
    description:
      "Type the organization name in the search bar or locate the organization manually in the list.",
  },
  {
    name: "Joining an Organization",
    image: "/ApprovalColSS.png",
    description: `Some organizations require approval from an organization owner in order to join. Join requests made to these organizations will first be sent to an organization owner for approval via email. These orgs can be identified via the red circles in the "Approval Required" column of the table.`,
  },
  {
    name: "Joining an Organization (cont.)",
    image: "/JoinRequestSS.png",
    description:
      "Click on the 'Request Access' or 'Join' button to send a join request to the organization. A pop-up will appear asking you to confirm your request.",
  },
  {
    name: "Request Process and Timelines",
    image: "/InProgressStatusSS.png",
    description:
      "After submitting a join request, the progress bar will update to orange indicating either in-progress or pending status depending on if organization owner approval is required.",
  },
  {
    name: "Request Process and Timelines (cont.)",
    image: "/FailedStatusSS.png",
    description:
      "If a join request fails or is denied by an organization owner, the progress bar will update to red indicating failed or rejected status.",
  },
  {
    name: "Request Process and Timelines (cont.)",
    image: "/JoinedStatusSS.png",
    description:
      "Once added to an organization, the progress bar will update to green indicating the joined status. Note:-  Processing time for requests may take up to 3 hours. For requests that require approval, the processing time starts after approval is completed.",
  },
  {
    name: "Signing In to GitHub",
    image: "/GithubSignInLinkSS.png",
    description: `After joining an organization, navigate to the
      <a
        href="https://github.com/enterprises/deloitte-global/sso"
        target="_blank"
        rel="noreferrer"
      >GitHub Sign-In Page</a>
      and sign in by entering your username in the following format: 
      <p></p>
      <p>&ltemail alias&gt_deloitte</p>
      <p></p>
      <p><strong>Note:</strong> You do not need your password</p>`,
  },
  {
    name: "Requesting Support or Reporting an Issue",
    image: "/SupportRequestSS.png",
    description:
      "To request support or to report an issue, click on the 'Support Request' link at the bottom of the dashboard.",
  },
];
