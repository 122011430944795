import * as React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import "./BasicAlert.css";

export default function BasicAlerts({
  message,
  severity,
  errKey,
  action,
  expandable,
  expandableText,
  expandableAction,
  expandableState,
  expandableData,
}) {
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity={severity} onClose={action}>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            flexDirection: "column",
            gap: "2px",
          }}
        >
          <span>{message}</span>
          {/* If expandable prop === true is passed */}
          {expandable && (
            <div>
              <div className="expand-btn" onClick={expandableAction}>
                <ArrowDropDownIcon
                  className={`keyboard-down-icon ${
                    expandableState ? "rotated" : ""
                  }`}
                />{" "}
                <span>{expandableText}</span>
              </div>
              <div
                className={`expandable-content ${
                  expandableState
                    ? "expandable-content-visible"
                    : "expandable-content-hidden"
                }`}
              >
                <hr style={{ margin: "0.5rem 0", width: "100%" }} />
                {Object.entries(expandableData).map(([key, error]) => (
                  <div style={{ marginTop: "10px", width: "100%" }} key={key}>
                    <p key={key}>{`${error.status}: ${error.message}`}</p>
                  </div>
                ))}{" "}
              </div>
            </div>
          )}
        </div>
      </Alert>
    </Stack>
  );
}
