import React, { useEffect, useMemo, useState } from "react";
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";

import Pagination from "../Pagination/Pagination";
import TableLegend from "../TableLegend/TableLegend";

const OrgsDataGrid = ({
  queryData,
  columns,
  isTableLoading,
  isInitialRender,
  isShowMoreOrgsLoading,
  rows,
  setCurrentPage,
  currentPage,
  totalRecords,
}) => {
  // Local states for pagination
  // const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [currentRecords, setCurrentRecords] = useState(queryData);
  const [nPages, setNPages] = useState(
    Math.ceil(queryData.length / recordsPerPage)
  );

  useEffect(() => {
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    setCurrentRecords(queryData.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [currentPage, recordsPerPage, queryData]);

  const calcPages = useMemo(() => {
    return Math.ceil(totalRecords / recordsPerPage);
  }, [totalRecords]);

  // Key for re-rendering the datagrid
  const dataGridKey = JSON.stringify(rows);
  // Text to show when data grid is empty
  const customLocaleText = {
    noRowsLabel: "No Organizations Found",
  };

  // Update records on query data change for searching
  useEffect(() => {
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    setCurrentRecords(queryData.slice(indexOfFirstRecord, indexOfLastRecord));
    setNPages(Math.ceil(queryData.length / recordsPerPage));
  }, [queryData]);

  // Custom footer for datagrid
  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        {/* Add what you want here */}
        <TableLegend />
        <GridFooter
          sx={{
            border: "none",
          }}
        />

        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        {/* / </Stack> */}
      </GridFooterContainer>
    );
  };

  return (
    <>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px !important",
            fontWeight: "bold !important",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#f6f6f6",
            maxHeight: "45px",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflowX: "hidden",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#f6f6f6",
            maxHeight: "45px",
            fontSize: "12px !important",
          },
          "& .MuiDataGrid-row": {
            minHeight: "60px !important",
            boxShadow: "none !important",
            borderBottom: "0.5px solid #d4d4d4",
          },
        }}
        key={dataGridKey}
        getRowId={(row) => row.id}
        id={currentRecords.id}
        rows={currentRecords}
        columns={columns}
        getRowClassName={(params) => {
          return params.row.recommended == true ? "recommended" : "";
        }}
        components={{ Footer: CustomFooter }}
        disableVirtualization
        disableSelectionOnClick
        getRowSpacing={(params) => ({
          top: params.isFirstVisible ? 0 : 3,
          bottom: params.isLastVisible ? 0 : 3,
        })}
        localeText={customLocaleText}
        loading={(isTableLoading && isInitialRender) || isShowMoreOrgsLoading}
      />
    </>
  );
};

export default OrgsDataGrid;
