import React, { useCallback, useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import "./Pagination.css";

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const [pageRange, setPageRange] = useState([1, 6]);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const scrollToTop = () => {
    const dataGridContainer = document.querySelector(
      ".MuiDataGrid-virtualScroller"
    );
    if (dataGridContainer) {
      dataGridContainer.scrollTop = 0;
    }
  };

  // Update page range to show when current page changes
  const updatePageNumbers = useCallback(() => {
    if (currentPage !== Math.floor((pageRange[1] + pageRange[0]) / 2)) {
      let lowerRange = currentPage - 2;
      let upperRange = currentPage + 3;

      // Edge case handling for when current page is at the start or end of the page range
      if (currentPage - 2 < 1 && currentPage + 3 > nPages + 1) {
        lowerRange = 1;
        upperRange = nPages + 1;
      } else {
        if (currentPage - 2 < 1) {
          lowerRange = 1;
          upperRange = 6;
        }
        if (currentPage + 3 > nPages + 1) {
          upperRange = nPages + 1;
          lowerRange = nPages + 1 - 5;
        }
      }

      setPageRange([lowerRange, upperRange]);
    }
  }, [currentPage, nPages, pageRange]);

  useEffect(() => {
    updatePageNumbers();
  }, [currentPage]);

  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  const onPgNumberClick = (pgNumber) => {
    setCurrentPage(pgNumber);
    scrollToTop();
  };

  const firstPage = () => {
    setCurrentPage(1);
    scrollToTop();
  };

  const lastPage = () => {
    setCurrentPage(nPages);
    scrollToTop();
  };

  return (
    <nav>
      <ul
        className="pagination justify-content-center"
        style={{ margin: "0px" }}
      >
        <li className="page-item">
          <a
            className="page-link"
            style={{
              color: currentPage === 1 ? "lightgray" : "black",
              border: "none",
            }}
            onClick={currentPage === 1 ? () => {} : firstPage}
          >
            {" "}
            <KeyboardDoubleArrowLeftIcon />
          </a>
        </li>
        <li className="page-item">
          <a
            className="page-link"
            style={{
              color: currentPage === 1 ? "lightgray" : "black",
              border: "none",
            }}
            onClick={currentPage === 1 ? () => {} : prevPage}
          >
            {" "}
            <KeyboardArrowLeftIcon />
          </a>
        </li>
        {Array.from(Array(nPages + 1).keys())
          ?.slice(pageRange[0], pageRange[1])
          ?.map((pgNumber) => (
            <li
              key={pgNumber}
              className={`page-item ${
                currentPage === pgNumber ? "active" : ""
              } `}
            >
              <a
                onClick={() => onPgNumberClick(pgNumber)}
                className="page-link"
              >
                {pgNumber}
              </a>
            </li>
          ))}
        <li className="page-item">
          <a
            className="page-link"
            style={{
              color: currentPage === nPages ? "lightgray" : "black",
              border: "none",
            }}
            onClick={currentPage === nPages ? () => {} : nextPage}
          >
            <KeyboardArrowRightIcon />
          </a>
        </li>
        <li className="page-item">
          <a
            className="page-link"
            style={{
              color: currentPage === nPages ? "lightgray" : "black",
              border: "none",
            }}
            onClick={currentPage === nPages ? () => {} : lastPage}
          >
            {" "}
            <KeyboardDoubleArrowRightIcon />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
