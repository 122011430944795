import { createSlice } from "@reduxjs/toolkit";
import { ErrorStatusCodeMapper } from "utils/ErrorStatusCodeMapper";

// The initial state of the auth slice is an object with authToken, userAccount, and permissions properties.
const initialState = {};

// The auth slice is used to maintain the authentication state of the user throughout the application.
export const ErrorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    addError: (state, action) => ({
      ...state,
      [action.payload.url]: {
        message: action.payload.message || "Unknown Error Occurred",
        status: action.payload.status,
      },
    }),
    removeError: (state, action) => {
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    },
    removeAllErrors: (state) => (state = initialState),
  },
});

export const { addError, removeError, removeAllErrors } = ErrorSlice.actions;
