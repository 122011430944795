import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getActiveDirectoryAuthToken } from "utils/getActiveDirectoryAuthToken";
import { v4 as uuidv4 } from "uuid";

// Base RTK Query API setup for platform service API calls.
export const platformServiceApiSlice = createApi({
  reducerPath: "platformServiceApi",
  baseQuery: fetchBaseQuery({
    // Set the base URL for the platformServiceApi.
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api`,
    // Prepare headers function is used to set the headers for each request to the platformServiceApi.
    prepareHeaders: async (headers, { getState }) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      headers.set("x-correlation-id", uuidv4());

      // Fetch fresh authToken from Active Directory and append it to the headers. (Handles token refresh if expired.)
      const authToken = await getActiveDirectoryAuthToken();
      headers.set("Authorization", `Bearer ${authToken}`);
      return headers;
    },
  }),
  // Tag types are used to group endpoints together in the Redux store.
  tagTypes: ["Platform"],
  endpoints: (builder) => ({}),
});
