import React from "react";

// ExtractUserPermissions is a function that takes in userOrgData as a parameter and returns a list of permissions.
const ExtractUserPermissions = async (userOrgData) => {
  // Extracts the orgId and userType from userOrgData and returns as a list of permissions.
  try {
    let permissions = [];

    userOrgData?.map((org) => {
      permissions.push({
        orgId: org.id,
        userType: org.userType,
      });
    });

    return permissions;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export default ExtractUserPermissions;
