import { platformServiceApiSlice } from "state/services/platformServiceApiSlice";

// The `platformServiceApiSlice` object is used to generate hooks that can be used to make API calls to the platform service.
export const platformServiceApiQueries =
  // The `injectEndpoints` method is used to define the API endpoints for the platform service.
  platformServiceApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      // The `getPlatforms` query is used to retrieve all platforms.
      getPlatforms: builder.query({
        query: () => ({
          url: `/platforms`,
        }),
        transformResponse: (responseData) => {
          return responseData.platforms[0];
        },
        providesTags: [{ type: "Platform", id: "Platforms" }],
      }),
    }),
  });

export const { useGetPlatformsQuery, useLazyGetPlatformsQuery } =
  platformServiceApiQueries;
