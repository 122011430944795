import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

const ReusableMultiSelectModal = ({
  selectedRows,
  show,
  close,
  action,
  allRows,
}) => {
  const [rejectReason, setRejectReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [params, setParams] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (selectedRows.length > 0) {
      selectedRows?.forEach((id) => {
        const row = allRows.find((r) => r.id === id);
        setParams((prev) => [...prev, row]);
      });
      setLoader(false);
    }
  }, [selectedRows]);

  const actionHandler = () => {
    close();
    action()(rejectReason, otherReason, params);
  };

  return (
    <div>
      <Dialog
        open={show}
        onClose={close}
        sx={{
          backdropFilter: "blur(1px)",
          //other styles here
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {/* Dialog Title */}
        <DialogTitle id="scroll-dialog-title" sx={{ background: "white" }}>
          Confirm Action
        </DialogTitle>
        {loader ? (
          <DialogContent>
            <div style={{ textAlign: "center" }}>Loading...</div>
          </DialogContent>
        ) : (
          <>
            <DialogContent
              sx={{
                maxHeight: "calc(100% - 209px)",
                maxWidth: "40vw",
                minWidth: "28vw",
                color: "black",
                letterSpacing: "0.00938em",
              }}
            >
              <p>
                {params?.[0]?.status === "rejected" ||
                params?.[0]?.status === "removed"
                  ? "Are you sure you want to reset profile for: "
                  : "Are you sure you want to revoke access for: "}
              </p>
              <div
                style={{
                  marginBottom: "30px",
                  overflowY: "auto",
                  maxHeight: "14rem",
                  paddingRight: "5px",
                }}
              >
                {params?.map((row, index) => (
                  <div
                    key={index}
                    style={{
                      listStyle: "none",
                      marginTop: "5px",
                      border: "1px solid lightgray",
                      borderRadius: "3px",
                      padding: "5px",
                      paddingLeft: "10px",
                      marginBottom: "5px",
                      backgroundColor: "whitesmoke",
                    }}
                  >
                    <strong role="orgName">{row.name} - </strong>
                    <span role="orgEmail">{row.email}</span>
                  </div>
                ))}
              </div>
              {params?.[0]?.status === "joined" && (
                <div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label
                      id="demo-simple-select-label"
                      style={{ marginBottom: "5px" }}
                    >
                      Reason for Revoking Access{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      labelid="demo-simple-select-label"
                      id="demo-simple-select"
                      className="reject-reason-select-dropdown"
                      value={rejectReason}
                      SelectDisplayProps={{
                        style: { paddingTop: 8, paddingBottom: 8 },
                      }}
                      onChange={(event) => setRejectReason(event.target.value)}
                    >
                      <MenuItem value={"Not a member of team"}>
                        User is no longer a member of the organization
                      </MenuItem>
                      <MenuItem value={"Does not have access permission"}>
                        User does not have access permission
                      </MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </div>
                  {rejectReason === "Other" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label style={{ marginTop: "20px", marginBottom: "5px" }}>
                        Please Specify <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        id="request-reason-multiline"
                        placeholder={"Reason for revoking access"}
                        className="reject-reason-text-field"
                        value={otherReason}
                        multiline
                        rows={3}
                        onChange={(event) => setOtherReason(event.target.value)}
                      />
                    </div>
                  )}
                </div>
              )}
              {params?.[0]?.status === "rejected" ||
              params?.[0]?.status === "removed" ? (
                <p style={{ fontSize: "12px", color: "", marginBottom: "0px" }}>
                  <strong>Note:</strong> Users whose profiles have been reset
                  will be able to request access to this organization again
                  immediately.
                </p>
              ) : (
                <></>
              )}
              <p>
                {params?.[0]?.status === "joined" ? (
                  <p style={{ marginTop: "20px", fontSize: "12px" }}>
                    <strong>Note: </strong>Processing times for removing a user
                    may take up to 3 hours after revoking access.
                  </p>
                ) : (
                  ""
                )}
              </p>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "flex-end" }}>
              <Stack
                sx={{
                  padding: "0.2rem 0rem",
                  margin: "0 auto",
                  alignContent: "center",
                  justifyContent: "center",
                }}
                direction="row"
                spacing={5}
              >
                <Button
                  sx={{
                    backgroundColor: "white",
                    border: "1px solid gray",
                    letterSpacing: "0.5px",
                    padding: "4px 16px",
                    borderRadius: "3px",
                    fontSize: "14px",
                    color: "black",
                  }}
                  onClick={close}
                >
                  Cancel{" "}
                </Button>
                <Button
                  role="requestAccessBtn"
                  sx={{
                    backgroundColor: "#d90000",
                    letterSpacing: "0.5px",
                    padding: "4px 16px",
                    borderRadius: "3px",
                    fontSize: "14px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#b90202",
                      color: "white",
                    },
                  }}
                  onClick={() => actionHandler()}
                  disabled={
                    params?.[0]?.status === "joined" &&
                    (rejectReason === "" ||
                      (rejectReason === "Other" && otherReason === ""))
                      ? true
                      : false
                  }
                >
                  {params?.[0]?.status === "joined"
                    ? "Revoke Access"
                    : "Reset Profile"}
                </Button>
              </Stack>
            </DialogActions>{" "}
          </>
        )}
      </Dialog>
    </div>
  );
};

export default ReusableMultiSelectModal;
