import React, { useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { REASON_COLUMN_CHARACTER_TRUNCATE_LIMIT } from "utils/constants";

import "./ReasonColumn.css";

const ReasonColumn = (props) => {
  // state for expanding reason
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      {!expanded &&
      props.params.row?.reason?.length >
        REASON_COLUMN_CHARACTER_TRUNCATE_LIMIT ? (
        <div>
          <span
            className="table-cell-trucate-reason"
            style={{ paddingLeft: "0" }}
          >
            {props.params.row?.reason?.slice(
              0,
              REASON_COLUMN_CHARACTER_TRUNCATE_LIMIT
            ) + "..."}
          </span>
          <span
            className="table-cell-trucate-expand"
            onClick={() => setExpanded(true)}
          >
            {"See more"}
          </span>
        </div>
      ) : (
        <div style={{ padding: "5px 0px" }}>
          <span
            className="table-cell-trucate-reason"
            style={{ paddingLeft: "0" }}
          >
            {props.params.row?.reason}
          </span>

          {props.params.row?.reason?.length >
            REASON_COLUMN_CHARACTER_TRUNCATE_LIMIT && (
            <span
              className="table-cell-trucate-expand"
              onClick={() => setExpanded(false)}
            >
              {"See less"}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default ReasonColumn;
