import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SecurityIcon from "@mui/icons-material/Security";
import SettingsIcon from "@mui/icons-material/Settings";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Fade,
  Grid,
  InputAdornment,
  Paper,
  styled,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import { useUpdateOrgMutation } from "state/rtkQuery/org/orgMutations";
import { useGetOrgByIdQuery } from "state/rtkQuery/org/orgQueries";
import { useGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import {
  useCreateUserMutation,
  useUpdateUserOrgStatusBatchWithExcelMutation,
  useUpdateUserOrgStatusMutation,
} from "state/rtkQuery/user/userMutations";
import { useLazyGetUserQuery } from "state/rtkQuery/user/userQueries";
import { convertBytes } from "utils/bytesRepresentationMapper";
import {
  SIGNUP_BANNER_DEFAULT,
  TOKEN_INACTIVITY_DAYS_DEFAULT,
  TOKEN_INACTIVITY_DAYS_LOWER_LIMIT,
  TOKEN_INACTIVITY_DAYS_UPPER_LIMIT,
  TOKEN_VALIDITY_DAYS_DEFAULT,
  TOKEN_VALIDITY_DAYS_LOWER_LIMIT,
  TOKEN_VALIDITY_DAYS_UPPER_LIMIT,
} from "utils/constants";

import BasicAlerts from "components/BasicAlert/BasicAlert";
import LoadingButton from "components/LoadingButton/LoadingButton";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const OrgSettings = () => {
  // State variables & queries for adding file upload
  const [selectedExcelFile, setSelectedExcelFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [fileUploadResponse, setFileUploadResponse] = useState(null);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [
    updateUserOrgStatusWithExcel,
    {
      data: updatedUserOrgStatusWithExcelData,
      isLoading: isUpdateUserOrgStatusWithExcelLoading,
      isSuccess: isUpdateUserOrgStatusWithExcelSuccess,
      isError: isUpdateUserOrgStatusWithExcelError,
    },
  ] = useUpdateUserOrgStatusBatchWithExcelMutation();

  // State variables & queries for adding a user to the organization
  const [userEmail, setUserEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [userEmailError, setUserEmailError] = useState("");
  const [isCheckingUser, setIsCheckingUser] = useState(false);
  const [userExists, setUserExists] = useState(null);
  const [addUserSuccess, setAddUserSuccess] = useState(false);
  const [getUser, { data: userData, isLoading: isUserDataLoading }] =
    useLazyGetUserQuery();
  const [
    createUser,
    {
      isLoading: isCreateUserLoading,
      isSuccess: isCreateUserSuccess,
      isError: isCreateUserError,
    },
  ] = useCreateUserMutation();
  const [
    updateUserOrgStatus,
    {
      data: updatedUserOrgStatusData,
      isLoading: isUpdateUserOrgStatusLoading,
      isSuccess: isUpdateUserOrgStatusSuccess,
      isError: isUpdateUserOrgStatusError,
    },
  ] = useUpdateUserOrgStatusMutation();

  // State variables & queries for updating organization settings
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const { orgId } = useParams();
  const { data: orgData, isLoading: isOrgDataLoading } =
    useGetOrgByIdQuery(orgId);
  const [
    updateOrg,
    {
      data: updatedOrgData,
      isSuccess: isUpdateOrgSuccess,
      isLoading: isUpdateOrgLoading,
      isError: isUpdateOrgError,
    },
  ] = useUpdateOrgMutation();

  const [orgInfo, setOrgInfo] = useState({
    name: "",
    signup_banner: SIGNUP_BANNER_DEFAULT,
    cred_scan: {
      enabled: false,
      in_active_days_limit: TOKEN_INACTIVITY_DAYS_DEFAULT,
      token_validity_days: TOKEN_VALIDITY_DAYS_DEFAULT,
    },
    visible: false,
    approval_required: false,
  });

  useEffect(() => {
    if (orgData) {
      setOrgInfo({
        name: orgData?.name,
        signup_banner: orgData?.signup_banner || SIGNUP_BANNER_DEFAULT,
        cred_scan: {
          enabled: orgData?.cred_scan?.enabled,
          in_active_days_limit:
            orgData?.cred_scan?.in_active_days_limit ||
            TOKEN_INACTIVITY_DAYS_DEFAULT,
          token_validity_days:
            orgData?.cred_scan?.token_validity_days ||
            TOKEN_VALIDITY_DAYS_DEFAULT,
        },
        visible: orgData?.visible,
        approval_required: orgData?.approval_required,
      });
    }
  }, [orgData]);

  // Get platform id
  const { data: platformData } = useGetPlatformsQuery();
  const currentUserEmail = useSelector(
    (state) => state.auth?.userAccount?.username
  );

  /**
   * Function to handle file input change for adding multiple users to the organization via excel file
   * Only allows excel file types to be uploaded
   */
  const handleFileInputChange = (e) => {
    setFileUploadSuccess(false);
    setFileUploadResponse(null);

    if (!e.target.files[0].name.includes(".xlsx")) {
      setFileError("Invalid file type. Please upload an excel file.");
      return;
    }

    setFileError("");
    setSelectedExcelFile(e.target.files[0]);
  };

  // Function to handle file upload for adding multiple users to the organization via excel file
  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append("file", selectedExcelFile);
    formData.append("org_id", orgId);
    formData.append("changed_by", currentUserEmail);

    const res = await updateUserOrgStatusWithExcel({
      body: formData,
    });

    setFileUploadResponse(res?.data);
  };

  useEffect(() => {
    if (isUpdateUserOrgStatusWithExcelSuccess) {
      setSelectedExcelFile(null);
      setFileUploadSuccess(true);
    }
  }, [isUpdateUserOrgStatusWithExcelSuccess]);

  // Function to handle user email change for adding a user to the organization
  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value);
    setUserEmailError("");
    setUserExists(null);
    setUserId("");
  };

  // Function for validation checks & to check if user to be added to org exists in the database already
  useEffect(() => {
    const checkUserInDatabase = setTimeout(async () => {
      if (userEmail === "") {
        return;
      }

      setAddUserSuccess(false);
      if (!userEmail.includes("@") || !userEmail.includes(".")) {
        setUserEmailError("Invalid email address");
        return;
      }

      setIsCheckingUser(true);
      const res = await getUser({ email: userEmail });
      if (res.isSuccess && res?.data?.length > 0) {
        setUserExists(true);
        setUserId(res?.data[0]?._id);
      } else {
        setUserExists(false);
      }
      setIsCheckingUser(false);
    }, 500);

    return () => {
      clearTimeout(checkUserInDatabase);
    };
  }, [userEmail]);

  /**
   * Function to handle adding a user to the organization
   * If the user exists in the database, update the user's organization membership status
   * If the user does not exist in the database, add the user to the database
   */
  const handleAddUser = () => {
    if (userExists) {
      let body = {
        platforms: {
          [platformData?._id]: {
            organizations: {
              [orgId]: {
                membership_status: "Requested",
                changed_by: currentUserEmail,
                reason: "testing patch",
              },
            },
          },
        },
      };

      updateUserOrgStatus({
        userId: userId,
        body: body,
        attribute: "membership_status",
      });
    } else if (userExists === false) {
      const userObj = {
        email: userEmail.toLowerCase(),
        platforms: {
          [platformData?._id]: {
            tos_accepted: false,
            name: platformData?.name,
            organizations: {
              [orgId]: {
                membership_status: "Requested",
                changed_by: currentUserEmail,
                reason: "testing post",
              },
            },
          },
        },
      };
      createUser(userObj);
    }
  };

  useEffect(() => {
    if (isCreateUserSuccess || isUpdateUserOrgStatusSuccess) {
      setUserEmail("");
      setUserExists(null);
      setAddUserSuccess(true);
    }
  }, [isCreateUserSuccess, isUpdateUserOrgStatusSuccess]);

  // Function to handle input change for organization settings
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    let newValue = type === "checkbox" ? checked : value;

    if (type === "number" && value !== "") {
      newValue = Number(value);
    }

    setOrgInfo((prevOrgInfo) => {
      const newState = { ...prevOrgInfo };
      addInputChangeToOrgObject(newState, name, newValue);
      return newState;
    });
  };

  // Function to sanitize the input values on blur event for token notification settings
  const sanitizeTokenNotificationInputOnBlur = (
    e,
    lowerLimit,
    upperLimit,
    tokenField
  ) => {
    // cut out all preceding zeros
    e.target.value = Number(e.target.value);

    // set the value to the lower limit if it's less than the lower limit and to the upper limit if it's greater than the upper limit
    if (e.target.value < lowerLimit) {
      setOrgInfo((prevOrgInfo) => {
        const newState = { ...prevOrgInfo };
        addInputChangeToOrgObject(newState, tokenField, lowerLimit);
        return newState;
      });
    } else if (e.target.value > upperLimit) {
      setOrgInfo((prevOrgInfo) => {
        const newState = { ...prevOrgInfo };
        addInputChangeToOrgObject(newState, tokenField, upperLimit);
        return newState;
      });
    }
  };

  // Function to add input change to the organization object (allows use of dot notation for MUI field names to better represent object structure)
  const addInputChangeToOrgObject = (nestedObj, keys, value) => {
    // keys are in dot notation, so we split them into an array
    keys = Array.isArray(keys) ? keys : keys.split(".");

    // if there's only one key left, we're at the final nested object else we recurse
    if (keys.length === 1) {
      nestedObj[keys[0]] = value;
    } else {
      addInputChangeToOrgObject(nestedObj[keys[0]], keys.slice(1), value);
    }
  };

  // Function to handle save button click for updating organization settings
  const handleSave = () => {
    updateOrg({ orgId: orgId, body: orgInfo });
  };

  // Function to handle success or error state of updating organization settings
  useEffect(() => {
    if (isUpdateOrgSuccess) {
      setIsUpdateSuccess(true);
    }
    if (
      isUpdateOrgSuccess ||
      isUpdateOrgError ||
      isUpdateUserOrgStatusWithExcelError ||
      isCreateUserError ||
      isUpdateUserOrgStatusError
    ) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }
  }, [
    isUpdateOrgSuccess,
    isUpdateOrgError,
    isUpdateUserOrgStatusWithExcelError,
    isCreateUserError,
    isUpdateUserOrgStatusError,
  ]);

  return (
    <Container style={{ maxWidth: "1300px", flex: "1", marginTop: "3rem" }}>
      {isUpdateSuccess && (
        <Box
          sx={{
            margin: "auto",
            marginBottom: "2rem",
          }}
        >
          <BasicAlerts
            severity={"success"}
            message={"Organization information successfully updated!"}
            action={() => setIsUpdateSuccess(false)}
          />
        </Box>
      )}
      <Box
        sx={{
          gap: "10px",
          margin: "auto",
        }}
      >
        <p
          style={{ fontSize: "20px", marginBottom: "10px", fontWeight: "bold" }}
        >
          {isOrgDataLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: "10px",
                fontWeight: "normal",
              }}
            >
              <div
                className="spinner-border spinner-border-md"
                style={{
                  color: "hsl(81.43deg 69.37% 43.53%)",
                  width: "1.7rem",
                  height: "1.7rem",
                }}
              ></div>
              <span>Fetching org settings...</span>
            </div>
          ) : (
            <div>
              {orgInfo?.name}
              <Typography variant="subtitle1">Org Settings</Typography>{" "}
            </div>
          )}
        </p>
        <hr
          style={{
            backgroundColor: "#d0d7de",
            width: "100%",
            marginBottom: "2rem",
          }}
        ></hr>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginBottom: "2rem",
          }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              style={{
                padding: "20px",
                paddingBottom: "50px",
                paddingLeft: "30px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <PersonAddIcon /> <span>Add Users</span>
              </Typography>
              <div style={{ marginTop: "1rem" }}>
                <Box sx={{ marginBottom: "8px" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Add Single User
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: "#808080" }}>
                    Add a single user to the organization by entering their
                    email
                  </Typography>
                </Box>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <TextField
                    placeholder="Email Address"
                    multiline
                    fullWidth
                    margin="none"
                    value={userEmail}
                    name="signup_banner"
                    onChange={handleUserEmailChange}
                    error={userEmailError !== ""}
                    sx={{
                      width: "25%",
                      "& .MuiInputBase-root": {
                        backgroundColor: "#f6f8fa",
                        padding: "9.5px 14px",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused": {
                          backgroundColor: "white",
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {isCheckingUser ? (
                            <div
                              className="spinner-border spinner-border-md"
                              style={{
                                color: "hsl(81.43deg 69.37% 43.53%)",
                                width: "1.3rem",
                                height: "1.3rem",
                              }}
                            ></div>
                          ) : userExists !== null ? (
                            <CheckIcon style={{ color: "green" }} />
                          ) : (
                            <></>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <LoadingButton
                    text={"Add User"}
                    width={"10%"}
                    variant={"contained"}
                    backgroundColor={"#86BC25"}
                    color={"#fff"}
                    disabled={
                      isCheckingUser ||
                      userExists === null ||
                      isCreateUserLoading ||
                      isUpdateUserOrgStatusLoading
                    }
                    height={"34px"}
                    loadingText={"Adding..."}
                    onClickFunc={handleAddUser}
                    loadingState={
                      isCreateUserLoading || isUpdateUserOrgStatusLoading
                    }
                  />
                </div>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "#d32f2f", marginTop: "3px", marginLeft: "8px" }}
                >
                  {userEmailError === "" ? "" : userEmailError}
                </Typography>
                {addUserSuccess && (
                  <Box
                    sx={{
                      mt: 1.5,
                      p: 2,
                      backgroundColor: "#e8f5e9",
                      borderRadius: "8px",
                      border: "1px solid #b9f6ca",
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Zoom in={true} style={{ transitionDelay: "100ms" }}>
                      <TaskAltIcon sx={{ color: "#2e7d32" }} />
                    </Zoom>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#2e7d32",
                        fontWeight: "medium",
                      }}
                    >
                      Success! User was added to the organization!
                    </Typography>
                  </Box>
                )}
              </div>
              <div>
                <Box sx={{ marginBottom: "8px", marginTop: "2rem" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Add Multiple Users
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: "#808080" }}>
                    Add multiple users to the organization in one go by
                    uploading an excel file
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: "#808080" }}>
                    1. Download the{" "}
                    <a href="/bulk-user-upload-template.xlsx" download>
                      excel file template
                    </a>
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: "#808080" }}>
                    2. Add the emails of the users you wish to be added under
                    the <strong>EMAILS</strong> column in the excel file
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: "#808080" }}>
                    3. Select and upload the filled excel file below
                  </Typography>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <TextField
                      placeholder="No File Selected"
                      multiline
                      fullWidth
                      margin="none"
                      value={selectedExcelFile ? selectedExcelFile.name : ""}
                      name="file"
                      error={fileError !== ""}
                      sx={{
                        width: "25%",
                        "& .MuiInputBase-root": {
                          backgroundColor: "#f6f8fa",
                          padding: "9.5px 14px",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused": {
                            backgroundColor: "white",
                          },
                        },
                      }}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            {selectedExcelFile ? (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  alignItems: "center",
                                  fontSize: "12px",
                                }}
                              >
                                <span>
                                  {convertBytes(selectedExcelFile.size)}
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      sx={{
                        color: "#000",
                        border: "1px solid #86BC25",
                        height: "36px",
                        "&:hover": {
                          border: "1px solid #86BC25",
                        },
                      }}
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                    >
                      Select File
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileInputChange}
                        onClick={(e) => (e.target.value = null)}
                      />
                    </Button>
                    <LoadingButton
                      variant={"contained"}
                      backgroundColor={"#86BC25"}
                      color={"#fff"}
                      disabled={
                        !selectedExcelFile ||
                        isUpdateUserOrgStatusWithExcelLoading
                      }
                      height={"34px"}
                      onClickFunc={handleFileUpload}
                      loadingState={isUpdateUserOrgStatusWithExcelLoading}
                      loadingText={"Uploading..."}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <CloudUploadIcon />
                        <span>{"Upload file"}</span>
                      </div>
                    </LoadingButton>
                  </div>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#d32f2f",
                      marginTop: "3px",
                      marginLeft: "8px",
                    }}
                  >
                    {fileError === "" ? "" : fileError}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#4caf50",
                      fontWeight: "bold",
                      marginTop: "3px",
                      marginLeft: "8px",
                    }}
                  >
                    {fileUploadSuccess ? "File uploaded successfully!" : ""}
                  </Typography>
                  <Typography variant="subtitle2">
                    {fileUploadResponse?.error_count > 0 ? (
                      <Box
                        sx={{
                          mt: 1.5,
                          p: 2,
                          borderRadius: "8px",
                          border: "1px solid #eb8080",
                          backgroundColor: "#ffeded",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: "#d32f2f",
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <ErrorOutlineIcon style={{ color: "#d32f2f" }} />
                          {fileUploadResponse?.error_count} users were unable to
                          be processed
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          style={{ marginTop: "10px" }}
                        >
                          Please ensure the emails are valid and try again.
                        </Typography>
                        <ul
                          style={{
                            marginTop: "10px",
                            listStyleType: "none",
                            paddingLeft: "0",
                          }}
                        >
                          {fileUploadResponse?.failed_emails.map(
                            (email, index) => (
                              <Fade
                                in={true}
                                timeout={500 + index * 100}
                                key={index}
                              >
                                <li
                                  style={{
                                    background: "#ffe5e5",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    marginBottom: "6px",
                                    wordBreak: "break-all",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{ color: "#d32f2f" }}
                                  >
                                    {email}
                                  </Typography>
                                </li>
                              </Fade>
                            )
                          )}
                        </ul>
                      </Box>
                    ) : fileUploadResponse?.success ? (
                      // Success message section
                      <Box
                        sx={{
                          mt: 1.5,
                          p: 2,
                          backgroundColor: "#e8f5e9",
                          borderRadius: "8px",
                          border: "1px solid #b9f6ca",
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <Zoom in={true} style={{ transitionDelay: "100ms" }}>
                          <TaskAltIcon sx={{ color: "#2e7d32" }} />
                        </Zoom>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "#2e7d32",
                            fontWeight: "medium",
                          }}
                        >
                          Success! All users were successfully processed!
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              style={{
                padding: "20px",
                paddingBottom: "50px",
                paddingLeft: "30px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <SettingsIcon />
                <span>General Settings</span>
              </Typography>
              <div style={{ marginTop: "1rem" }}>
                <Box sx={{ marginBottom: "8px" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Sign Up Banner
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: "#808080" }}>
                    Message that will be displayed to users when they attempt to
                    request access to the organization.
                  </Typography>
                </Box>
                <TextField
                  placeholder="Signup Banner"
                  multiline
                  fullWidth
                  margin="none"
                  value={orgInfo.signup_banner}
                  name="signup_banner"
                  onChange={handleInputChange}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setOrgInfo((prevOrgInfo) => {
                        const newState = { ...prevOrgInfo };
                        addInputChangeToOrgObject(
                          newState,
                          "signup_banner",
                          SIGNUP_BANNER_DEFAULT
                        );
                        return newState;
                      });
                    }
                  }}
                  sx={{
                    width: "77%",
                    "& .MuiInputBase-root": {
                      backgroundColor: "#f6f8fa",
                      padding: "9.5px 14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        backgroundColor: "white",
                      },
                    },
                  }}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              style={{
                padding: "20px",
                paddingBottom: "50px",
                paddingLeft: "30px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <SecurityIcon /> <span>Credential Scan Settings</span>
              </Typography>
              <Box sx={{ display: "flex", marginTop: "16px" }}>
                <Grid item xs={12} md={4} sx={{}}>
                  <Checkbox
                    checked={orgInfo.cred_scan.enabled}
                    onChange={handleInputChange}
                    name="cred_scan.enabled"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid item xs={12} md={8} sx={{ paddingTop: "8px" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Enable Credential Scan
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: "#808080" }}>
                    Enables scans for PAT and SSH tokens in the organization's
                    repositories and allows for configuration of the token
                    notification settings below.
                  </Typography>
                  <Box sx={{ marginTop: "1rem" }}>
                    <Box sx={{ marginBottom: "8px", width: "75%" }}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Token Inactivity Limit
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: "#808080" }}>
                        Number of days a PAT or SSH token is allowed to remain
                        inactive for. After this period, an email will be sent
                        letting the user and organziation owners know that the
                        token no longer meets the organization's security
                        requirements.
                      </Typography>
                    </Box>
                    <TextField
                      type="number"
                      disabled={!orgInfo.cred_scan.enabled}
                      value={orgInfo.cred_scan.in_active_days_limit}
                      name="cred_scan.in_active_days_limit"
                      onChange={handleInputChange}
                      onBlur={(e) =>
                        sanitizeTokenNotificationInputOnBlur(
                          e,
                          TOKEN_INACTIVITY_DAYS_LOWER_LIMIT,
                          TOKEN_INACTIVITY_DAYS_UPPER_LIMIT,
                          "cred_scan.in_active_days_limit"
                        )
                      }
                      sx={{
                        width: "8%",
                        "& .MuiInputBase-root": { backgroundColor: "#f6f8fa" },
                        "& .MuiInputBase-input": { padding: "9.5px 14px" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused": {
                            backgroundColor: "white",
                          },
                        },
                      }}
                      fullWidth
                      InputProps={{
                        inputProps: {
                          min: TOKEN_INACTIVITY_DAYS_LOWER_LIMIT,
                          max: TOKEN_INACTIVITY_DAYS_UPPER_LIMIT,
                        },
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "#808080", marginTop: "6px" }}
                    >
                      Min {TOKEN_INACTIVITY_DAYS_LOWER_LIMIT} days, Max{" "}
                      {TOKEN_INACTIVITY_DAYS_UPPER_LIMIT} days
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: "1.5rem" }}>
                    <Box sx={{ marginBottom: "8px", width: "75%" }}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Token Validity Limit
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: "#808080" }}>
                        Number of days a PAT or SSH token is allowed to remain
                        valid for. After this period, an email will be sent
                        letting the user and organization owners know that the
                        token no longer meets the organization's security
                        requirements.
                      </Typography>
                    </Box>
                    <TextField
                      type="number"
                      disabled={!orgInfo.cred_scan.enabled}
                      value={orgInfo.cred_scan.token_validity_days}
                      name="cred_scan.token_validity_days"
                      onChange={handleInputChange}
                      onBlur={(e) =>
                        sanitizeTokenNotificationInputOnBlur(
                          e,
                          TOKEN_VALIDITY_DAYS_LOWER_LIMIT,
                          TOKEN_VALIDITY_DAYS_UPPER_LIMIT,
                          "cred_scan.token_validity_days"
                        )
                      }
                      sx={{
                        width: "8%",
                        "& .MuiInputBase-root": { backgroundColor: "#f6f8fa" },
                        "& .MuiInputBase-input": { padding: "9.5px 14px" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused": {
                            backgroundColor: "white",
                          },
                        },
                      }}
                      fullWidth
                      InputProps={{
                        inputProps: {
                          min: TOKEN_VALIDITY_DAYS_LOWER_LIMIT,
                          max: TOKEN_VALIDITY_DAYS_UPPER_LIMIT,
                        },
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "#808080", marginTop: "6px" }}
                    >
                      Min {TOKEN_VALIDITY_DAYS_LOWER_LIMIT} days, Max{" "}
                      {TOKEN_VALIDITY_DAYS_UPPER_LIMIT} days
                    </Typography>
                  </Box>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              style={{
                padding: "20px",
                paddingBottom: "30px",
                paddingLeft: "30px",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Access Settings
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "16px",
                }}
              >
                <Grid item xs={12} md={4} sx={{}}>
                  <Checkbox
                    checked={orgInfo.visible}
                    onChange={handleInputChange}
                    name="visible"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid item xs={12} md={8} sx={{ paddingTop: "8px" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Enable Org Visibility
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: "#808080" }}>
                    Allows the organization to be visible on the home dashboard
                    for all users who sign into the portal.
                  </Typography>
                </Grid>
              </Box>
              <Box sx={{ display: "flex", marginTop: "8px" }}>
                <Grid item xs={12} md={4} sx={{}}>
                  <Checkbox
                    checked={orgInfo.approval_required}
                    onChange={handleInputChange}
                    name="approval_required"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid item xs={12} md={8} sx={{ paddingTop: "8px" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Enable Approval Required
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: "#808080" }}>
                    Allows the organization to require approval from an org
                    owner or approver before a user can join the organization.
                  </Typography>
                </Grid>
              </Box>
            </Paper>
          </Grid> */}
          <hr
            style={{
              backgroundColor: "#d0d7de",
              width: "100%",
            }}
          ></hr>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "bottom",
            }}
          >
            <LoadingButton
              text={"Save"}
              width={"10%"}
              variant={"contained"}
              backgroundColor={"#86BC25"}
              color={"#fff"}
              loadingText={"Updating..."}
              onClickFunc={handleSave}
              loadingState={isUpdateOrgLoading}
            />
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default OrgSettings;
