import React from "react";
import { Link } from "react-router-dom";
import { Box, Container } from "@mui/material";

const NotFound = () => {
  return (
    <Container>
      <Box
        sx={{
          height: 420,
          marginTop: "4rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <h3 style={{ fontWeight: "bold", marginBottom: "1rem" }}>
          404 - page not found
        </h3>
        <Link to="/dashboard">Go to home dashboard</Link>
      </Box>
    </Container>
  );
};

export default NotFound;
