import React from "react";
import { Outlet } from "react-router-dom";

import Errors from "components/Errors/Errors";
import Footer from "components/Footer/Footer";
import NavBar from "components/navigation/NavBar";

const Layout = () => {
  const location = window.location.pathname;

  return (
    <div>
      <NavBar />
      <div style={{ height: "100vh" }}>
        {location.includes("admin") ? null : <Errors />}
        <Outlet />
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
