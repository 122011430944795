import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { Box, Button, Container } from "@mui/material";
import { callMsGraph } from "api/graph";
import emuPortalBackground from "assets/BackgroundECREMULOGIN.png";
import CryptoJS from "crypto-js";
import { useLazyGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import {
  useLazyGetUserQuery,
  useLazyGetUserWithOrgMembershipStatusesQuery,
} from "state/rtkQuery/user/userQueries";
import { setAuthState } from "state/slices/auth/authSlice";
import ExtractUserPermissions from "utils/FetchUserPermissions";
import { getMSALToken } from "utils/getMSALToken";

import ProgressBar from "./ProgressBar/ProgressBar";

import { loginRequest } from "../../authConfig/authConfig";

import "./home.css";

function Home() {
  const { instance, inProgress, accounts } = useMsal();
  const auth = useIsAuthenticated();
  const location = useLocation();
  const [from, setFrom] = useState("/dashboard");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (location?.state?.from?.pathname) {
      setFrom(location.state?.from?.pathname);
      if (location?.state?.from?.search) {
        setFrom(from + location?.state?.from?.search);
      }
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [auth, inProgress, instance]);

  // RTK Query for getting userRecord from db
  const [fetchPlatformData] = useLazyGetPlatformsQuery();
  const [fetchUserData] = useLazyGetUserQuery();
  const [fetchUserDataWithOrgStatus] =
    useLazyGetUserWithOrgMembershipStatusesQuery();

  const [accountState, setAccountState] = useState(accounts?.[0]);

  // Function to handle user login. If user is successfully logged in, set accountState to trigger useEffect to get auth state
  const handleLogin = async (loginType) => {
    if (loginType === "popup") {
      try {
        const res = await instance.loginPopup(loginRequest);
        setAccountState(res?.account);
      } catch (e) {
        setLoader(false);
        console.log(e);
      }
    }
  };

  const isAuthed = useIsAuthenticated();
  const authState = useSelector((state) => state.auth);
  const [tmp, setTmp] = useState();

  // Logic to bypass login page if user is already SSO'ed
  useEffect(() => {
    // Function to get auth state once user has SSO'ed
    const getAuthState = async () => {
      setLoader(true);

      // Init authState
      let authStateUpdated = {
        authToken: null,
        userAccount: null,
        permissions: [],
      };

      // Fetch id token and access token from Azure AD
      const accessTokenRes = await getMSALToken(instance, accountState, setTmp);
      authStateUpdated.authToken = accessTokenRes?.idToken;
      authStateUpdated.accessToken = accessTokenRes?.accessToken;

      authStateUpdated.userAccount = accountState;

      // Fetch user account info from MS Graph API
      const msGraphRes = await callMsGraph(
        authStateUpdated?.accessToken,
        accountState?.username
      );
      authStateUpdated.userAccount.givenName = msGraphRes?.givenName;
      authStateUpdated.userAccount.surname = msGraphRes?.surname;

      // Set updated auth state with id token, access token, and user account info in redux store
      await dispatch(setAuthState(authStateUpdated));

      // Fetch platform data to get user data with org statuses from specific platform (only GitHub for now)
      const platformRes = await fetchPlatformData();

      // Fetch user data from DB via user service API
      const userRes = await fetchUserData({
        email: accountState.username,
      });
      authStateUpdated = JSON.parse(JSON.stringify(authStateUpdated));

      // Fetch user data with org statuses if user exists in DB and platform data call is successful
      if (
        userRes.isSuccess &&
        userRes.data.length !== 0 &&
        platformRes.isSuccess
      ) {
        const userOrgRes = await fetchUserDataWithOrgStatus({
          userId: userRes?.data?.[0]?._id,
          platformId: platformRes.data?._id,
        });

        // Extract user permissions for each org from user org statuses data and store in authState
        if (userOrgRes.isSuccess) {
          authStateUpdated.permissions = await ExtractUserPermissions([
            ...userOrgRes?.data[0],
            ...userOrgRes?.data[1],
          ]);
        }
      }

      // Encrypt and store authState in local storage to rehydrate state on refresh
      localStorage.setItem(
        "authStateEncrypted",
        CryptoJS.AES.encrypt(
          JSON.stringify(authStateUpdated),
          "someKey"
        ).toString()
      );

      await dispatch(setAuthState(authStateUpdated));

      setLoader(false);
      navigate(from, { replace: true });
    };

    // If user is authenticated and accountState is not null, get auth state otherwise redirect to page from which user was redirected to login
    if (isAuthed && accountState && !authState.authToken) {
      getAuthState();
    } else if (isAuthed && accountState && authState.authToken) {
      navigate(from, { replace: true });
    }
  }, [isAuthed, accountState]);

  return loader ? (
    <Container>
      <Box
        sx={{
          height: 420,
          marginTop: "4rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <ProgressBar />
        <p style={{ marginTop: "1rem" }}>Fetching user data...</p>
      </Box>
    </Container>
  ) : (
    <div>
      {" "}
      <UnauthenticatedTemplate>
        <Container
          maxWidth={false}
          style={{
            display: "flex",
            height: "100vh",
            flexDirection: "row",
            padding: "none",
          }}
          className="homePageContainer"
        >
          <div className="loginPart">
            <div className="label-button-container">
              {" "}
              <label className="welcomeText">
                Welcome to the
                <p className="portal-name">
                  DT-Global Enterprise Code Repository (ECR)
                </p>
              </label>
              <Button
                className="login-button"
                onClick={() => handleLogin("popup")}
              >
                Sign In
              </Button>
            </div>
          </div>
          <div className="backgroundPicturePart">
            <img
              src={emuPortalBackground}
              alt="emuPortalBackground"
              className="spinningTest"
            />
          </div>
        </Container>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default Home;
