// Description: Constants used to map statuses from user org statuses API call to table progress bars.
export const adRequestStatusMap = {
  "Not-Requested": "Non-Member",
  Requested: "In-Progress",
  Joined: "Joined",
  Failed: "Failed",
  Pending: "Pending",
  Rejected: "Rejected",
  "Remove-Requested": "Joined",
  Removed: "Removed",
};

export const TOKEN_VALIDITY_DAYS_UPPER_LIMIT = 360;
export const TOKEN_VALIDITY_DAYS_LOWER_LIMIT = 90;
export const TOKEN_VALIDITY_DAYS_DEFAULT = 180;

export const TOKEN_INACTIVITY_DAYS_UPPER_LIMIT = 90;
export const TOKEN_INACTIVITY_DAYS_LOWER_LIMIT = 30;
export const TOKEN_INACTIVITY_DAYS_DEFAULT = 45;

export const SIGNUP_BANNER_DEFAULT = "No description available";

export const REASON_COLUMN_CHARACTER_TRUNCATE_LIMIT = 50;
export const EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_OWNER_VIEW = 28;
export const EMAIL_COLUMN_CHARACTER_TRUNCATE_LIMIT_APPROVER_VIEW = 35;

export const INACTIVE_LOGOUT_TIMER_EVENTS = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];
export const INACTIVE_LOGOUT_TIMER_DURATION = 900000;
