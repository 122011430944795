import React from "react";
import { Box } from "@mui/material";

import "./ErrorFallBack.css";

const ErrorFallBack = ({ error, resetErrorBoundary }) => {
  return (
    <Box
      sx={{
        height: 420,
        marginTop: "4rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      <h1>Oops! Something went wrong</h1>
      <p>We're sorry, but an error occurred while processing your request.</p>
      <p style={{ marginTop: "20px", marginBottom: "30px" }}>
        <strong>Error Message:</strong> {error.message}
      </p>
      <button className="try-again-btn" onClick={resetErrorBoundary}>
        Try Again
      </button>
      <p style={{ marginTop: "10px" }}>
        If the problem persists, please contact support{" "}
        <a
          href="https://deloitteglobal.service-now.com/sp?id=sc_cat_item&sys_id=4a417b4f1b17855cbcae55b0604bcb1c"
          target="_blank"
          style={{ color: "rgb(0 151 215)", fontWeight: "500" }}
          rel="noreferrer"
        >
          here
        </a>
      </p>
    </Box>
  );
};

export default ErrorFallBack;
