import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import SearchIcon from "@mui/icons-material/Search";
import { Container, Divider, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { costCenters } from "pages/homeDashboard/components/Modals/ConfirmationModal/ConfirmationModal";
import { useGetOrgByIdQuery } from "state/rtkQuery/org/orgQueries";
import { useGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import { useUpdateUserOrgStatusMutation } from "state/rtkQuery/user/userMutations";
import { useGetUsersByOrgAndStatusQuery } from "state/rtkQuery/user/userQueries";

import AdminDataGrid from "./components/AdminDataGrid/AdminDataGrid";
import AdminUserActions from "./components/AdminUserActions/AdminUserActions";
import MultiSelectModal from "./components/MultiSelectModal/MultiSelectModal";
import ReasonColumn from "./components/ReasonColumn/ReasonColumn";

import { getMSALToken } from "../../utils/getMSALToken";

import "./AdminDashboard.css";

const AdminDashboard = (props) => {
  const { instance, accounts } = useMsal();
  const { orgId } = useParams();

  const [authToken, setAuthToken] = useState();

  const userEmail = useSelector((state) => state.auth?.userAccount?.username);

  // Loader States
  const [isPageLoading, setIsPageLoading] = useState(false);

  // DataGrid data states
  const [query, setQuery] = useState("");
  const [queryData, setQueryData] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [usersCostCenters, setUsersCostCenters] = useState({});

  // Multi-select data states
  const [showModalMulti, setShowModalMulti] = useState(false);
  const [multiSelectAction, setMultiSelectAction] = useState();
  const [rejectReason, setRejectReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isMultiActionLoading, setIsMultiActionLoading] = useState(false);

  // Get MSAL token
  useEffect(() => {
    getMSALToken(instance, accounts, setAuthToken);
  }, []);

  // RTK Query to get platform data
  const {
    data: platformData,
    isLoading: isPlatformDataLoading,
    isSuccess: isPlatformDataSuccess,
  } = useGetPlatformsQuery();

  // RTK Query to get org data by orgId
  const { data: orgData, isLoading: isOrgDataLoading } =
    useGetOrgByIdQuery(orgId);

  // RTK Query to get users by org and status
  const {
    data: orgRequestsData,
    isLoading: isFetchingOrgRequestsLoading,
    isSuccess: isOrgRequestsSuccess,
    isError: isOrgRequestError,
  } = useGetUsersByOrgAndStatusQuery(
    { orgId: orgId, status: "Pending", platformId: platformData?._id },
    { skip: !isPlatformDataSuccess, pollingInterval: 300000 }
  );

  // Set rows and usersCostCenters state based on orgRequestsData
  useEffect(() => {
    if (isOrgRequestsSuccess) {
      setRows(orgRequestsData?.users);
      setQueryData(orgRequestsData?.users);

      // User cost centers need to be set in a separate state to allow for dynamic updates on the table by org owners and approvers
      // orgRequestsData?.users?.map((user) => {
      //   setUsersCostCenters((prev) => ({
      //     ...prev,
      //     [user.id]: user.costCenter,
      //   }));
      // });
    } else if (isOrgRequestError) {
      setRows([]);
      setQueryData([]);
    }
  }, [isOrgRequestsSuccess, isOrgRequestError, orgRequestsData]);

  // Set columns for MUI datagrid
  useEffect(() => {
    // if (rows && Object.keys(usersCostCenters).length > 0) {
    if (rows) {
      setColumns([
        // Requester's Name
        {
          field: "name",
          headerName: "Requester Name",
          headerClassName: "orgName-column-header",
          headerAlign: "left",
          align: "left",
          width: 210,
          renderCell: (params) => (
            <Tooltip title={params.row.name}>
              <span className="table-cell-trucate">{params.row.name}</span>
            </Tooltip>
          ),
        },
        // Requester's Email
        {
          field: "email",
          headerName: "Requester Email",
          description: "Requester Email",
          width: 230,
          headerAlign: "left",
          align: "left",
          filterable: false,
          renderCell: (params) => (
            <Tooltip title={params.row.email}>
              <span
                className="table-cell-trucate-email"
                style={{ paddingLeft: "None" }}
              >
                {params.row.email}
              </span>
            </Tooltip>
          ),
        },
        // Reason for Request
        {
          field: "reason",
          headerName: "Reason for Request",
          headerClassName: "",
          headerAlign: "center",
          align: "center",
          width: 400,
          renderCell: (params) => <ReasonColumn params={params} />,
        },
        // Cost Center field (commented out for now)
        // {
        //   field: "costCenter",
        //   headerName: "Cost Center",
        //   headerAlign: "center",
        //   align: "center",
        //   width: 190,
        //   renderCell: (params) => (
        //     <TextField
        //       sx={{
        //         "& .MuiInputBase-input": { padding: "7.5px 14px" },
        //       }}
        //       id="costCenter"
        //       select
        //       value={usersCostCenters[params.row.id]}
        //       onChange={(e) =>
        //         setUsersCostCenters((prev) => ({
        //           ...prev,
        //           [params.row.id]: e.target.value,
        //         }))
        //       }
        //       variant="outlined"
        //       style={{ width: "100%" }}
        //     >
        //       {costCenters.map((option) => (
        //         <MenuItem key={option.id} value={option.name}>
        //           {option.name}
        //         </MenuItem>
        //       ))}
        //     </TextField>
        //   ),
        // },
        // Request Date
        {
          field: "date",
          headerName: "Request Date",
          width: 200,
          align: "center",
          headerAlign: "center",
          filterable: true,
          renderCell: (params) => (
            <span style={{ fontSize: "12px" }}>{params.row.date}</span>
          ),
        },
        // Actions Buttons
        {
          field: "action",
          headerAlign: "center",
          headerName: "Actions",
          align: "center",
          width: 150,
          filterable: false,
          renderCell: (params) => (
            <AdminUserActions
              {...{ params }}
              platformId={platformData?._id}
              orgData={orgData}
              userEmail={userEmail}
            />
          ),
        },
      ]);
    }
  }, [rows]);

  const [currentPage, setCurrentPage] = useState(1);

  // Filter data based on search query
  useEffect(() => {
    setCurrentPage(1);
    setQueryData(
      rows.filter((post) => {
        if (query === "") {
          return rows;
        } else if (
          post?.email?.toLowerCase()?.includes(query.toLowerCase()) ||
          post?.name?.toLowerCase()?.includes(query.toLowerCase())
        ) {
          return post;
        }
      })
    );
  }, [query, rows]);

  // RTK Query to update user org status (accept/reject requests)
  const [updateUserOrgStatus, { isLoading: isUpdateUserOrgStatusLoading }] =
    useUpdateUserOrgStatusMutation();

  // Accept/Reject request handler
  const onRequestActionHandler = async (action, row) => {
    updateUserOrgStatus({
      userId: row.id,
      body: {
        platforms: {
          [platformData._id]: {
            organizations: {
              [orgData._id]: {
                membership_status:
                  action === "rejected" ? "Rejected" : "Requested",
                reason: rejectReason === "Other" ? otherReason : rejectReason,
                changed_by: userEmail.toLowerCase(),
              },
            },
          },
        },
      },
      attribute: "membership_status",
    });
  };

  // Function to handle multi-select users and perform action on each selected user
  const handleMultiSelectAction = async (action) => {
    handleClose();
    setIsMultiActionLoading(true);
    try {
      // Map over selected rows and perform action on each
      const apiCalls = selectedRows.map(async (selectedRowId) => {
        const row = rows.find((row) => row.id === selectedRowId);
        await onRequestActionHandler(action, row);
      });

      // Wait for all API calls to finish
      await Promise.all(apiCalls);

      // Clear selections and loading with minimum 1 second wait time
      setTimeout(() => {
        setIsMultiActionLoading(false);
        setSelectedRows([]);
      }, 1000);
    } catch (error) {
      setIsMultiActionLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  // Close MultiSelectModal
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
    } else {
      setShowModalMulti(false);
      setRejectReason("");
      setOtherReason("");
    }
  };

  // Search TextField styles
  const textStyles = styled({
    root: {
      "& .Mui-focused": {
        color: "#008080",
        outline: "1px solid  #008080",
      },
    },
  });

  return (
    <Container
      sx={{ maxWidth: "1300px !important", flex: "1", marginTop: "3rem" }}
    >
      {/* If page is loading or platform/org data is loading, show loader */}
      {isPageLoading || isPlatformDataLoading || isOrgDataLoading ? (
        <Box
          sx={{
            height: 420,
            marginTop: "4rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <TailSpin
            color="hsl(81.43deg 69.37% 43.53%)"
            height={50}
            width={50}
          />
        </Box>
      ) : (
        <div>
          {/* Show Multi-Select confirmation modal */}
          {showModalMulti && (
            <MultiSelectModal
              show={showModalMulti}
              close={handleClose}
              selectedRows={selectedRows}
              allRows={rows}
              onRequestActionHandler={handleMultiSelectAction}
              approvalAction={multiSelectAction}
              rejectReason={rejectReason}
              setRejectReason={setRejectReason}
              otherReason={otherReason}
              setOtherReason={setOtherReason}
            />
          )}
          {/* Top */}
          <Box sx={{ height: 600 }}>
            {/* Header */}
            <div className="admin-headerSearchFlex">
              <div className="admin-dashboard-header">
                <span className="admin-dashboard-header-content">
                  <strong>{orgData?.name}</strong>
                </span>
              </div>
              {/* Search TextField */}
              <div className="admin-dashboard-search">
                <TextField
                  id="outlined-search"
                  placeholder="Search by Email or Name"
                  type="search"
                  className={textStyles.root}
                  variant="outlined"
                  InputProps={{
                    style: {
                      height: "31px",
                      width: "250px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => setQuery(event.target.value)}
                />
              </div>
            </div>
            <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
              Pending Approvals
            </Typography>
            <Divider />
            {/* DataGrid */}
            <AdminDataGrid
              rows={rows}
              columns={columns}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              queryData={queryData}
              setShowModalMulti={setShowModalMulti}
              setMultiSelectAction={setMultiSelectAction}
              isMultiActionLoading={isMultiActionLoading}
              isTableLoading={isFetchingOrgRequestsLoading}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
            {/* Footer/Support Request */}
            <div
              className="admin-dashboard-support-container"
              style={{
                marginTop: "30px",
                padding: "10px 0px",
                paddingBottom: "2rem",
              }}
            >
              <p>
                Please fill our{" "}
                <a
                  href="https://deloitteglobal.service-now.com/sp?id=sc_cat_item&sys_id=4a417b4f1b17855cbcae55b0604bcb1c"
                  target="_blank"
                  className="request-support-link"
                  rel="noreferrer"
                >
                  Support Request
                </a>
                &nbsp; Form if you face any issues.
              </p>
            </div>
          </Box>
        </div>
      )}
    </Container>
  );
};

export default AdminDashboard;
